import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Hidden from '@mui/material/Hidden';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import pricing_asset_mobile_1 from '../../../../static/pricing/pricing-asset-mobile-1.svg';
import theme from '../../../../theme';
import ButtonCustom from '../../../atoms/Button';
import { selectFirstName, selectLastName } from '../pricingSlice';

const schema = yup.object().shape({
  firstName: yup.string().trim().required(),
  lastName: yup.string().trim().required(),
});

const useStyles = makeStyles((theme) => ({
  pricingName: {
    width: '100%',
    paddingTop: 80,
    paddingRight: 10,
    paddingLeft: 10,
    paddingBottom: 20,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 50,
    },
  },
  text_field_label: {
    color: theme.palette.primary.main,
  },
  text_field: {
    color: theme.palette.primary.main,
    '&:before': {
      borderColor: theme.palette.primary.main,
    },
    '&:after': {
      borderColor: theme.palette.primary.main,
    },
  },
  text_field_label_error: {
    color: theme.palette.error.main + ' !important',
  },
  text_field_error: {
    color: theme.palette.error.main + ' !important',
    '&:before': {
      borderColor: theme.palette.error.main + ' !important',
    },
    '&:after': {
      borderColor: theme.palette.error.main + ' !important',
    },
  },
  mobile_image: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    justifyContent: 'center',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  title_text: {
    fontSize: '2.1rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
  title_container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  input_container: {
    maxWidth: 270,
    marginTop: 50,
    padding: 30,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${pricing_asset_mobile_1})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
}));
export default function Pricing({ step, handleNext, isLoading }) {
  const { t } = useTranslation(null, { keyPrefix: 'pricing.name' });
  const pricing = useSelector((state) => state.pricing);
  const { formId, firstName, lastName } = pricing;
  const dispatch = useDispatch();
  const { control, formState, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { errors, isValid } = formState;
  const affiliate_id = localStorage.getItem('affiliate_id');

  const onSubmit = (data) => {
    if (isLoading) return;
    const firstName = data.firstName?.trim();
    const lastName = data.lastName?.trim();
    const formattedFirstName = `${firstName
      .charAt(0)
      .toUpperCase()}${firstName.slice(1)}`;
    const formattedLastName = `${lastName
      .charAt(0)
      .toUpperCase()}${lastName.slice(1)}`;
    dispatch(selectFirstName(formattedFirstName));
    dispatch(selectLastName(formattedLastName));
    handleNext({
      id: formId,
      step,
      first_name: formattedFirstName,
      last_name: formattedLastName,
      ...(affiliate_id ? { affiliate_id } : {}),
    });
    localStorage.removeItem('affiliate_id');
  };

  const classes = useStyles();
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ height: '100%' }}
      data-test="name-form"
      autoComplete="off"
    >
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.pricingName}
      >
        <Grid item xs={12} className={classes.title_container}>
          <Typography
            color={'primary'}
            variant={'h4'}
            className={classes.title_text}
          >
            <Hidden smUp>
              {t('title')}
              <br />
              {t('mobile.subtitle1')}
              <span style={{ display: 'inline-block' }}>
                {t('mobile.subtitle2')}
              </span>
            </Hidden>
            <Hidden smDown>
              {t('title')}
              <br />
              {t('subtitle1')}
              <br />
              {t('subtitle2')}
            </Hidden>
          </Typography>
        </Grid>
        <Grid container className={classes.input_container}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="firstName"
              defaultValue={firstName}
              render={({ field }) => (
                <TextField
                  sx={{ marginBottom: { xs: '10px', sm: '20px' } }}
                  id="firstName"
                  label={t('label.firstName')}
                  variant="standard"
                  InputLabelProps={{
                    className: errors.firstName
                      ? classes.text_field_label_error
                      : classes.text_field_label,
                  }}
                  InputProps={{
                    className: errors.firstName
                      ? classes.text_field_error
                      : classes.text_field,
                  }}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="lastName"
              defaultValue={lastName}
              render={({ field }) => (
                <TextField
                  sx={{ marginBottom: { xs: '10px', sm: '20px' } }}
                  id="lastName"
                  label={t('label.lastName')}
                  variant="standard"
                  InputLabelProps={{
                    className: errors.lastName
                      ? classes.text_field_label_error
                      : classes.text_field_label,
                  }}
                  InputProps={{
                    className: errors.lastName
                      ? classes.text_field_error
                      : classes.text_field,
                  }}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginTop: { xs: '30px', sm: '50px' } }}
        >
          <ButtonCustom
            type={'submit'}
            width={165}
            height={47}
            disabled={!isValid}
          >
            {!isLoading ? (
              t('button')
            ) : (
              <CircularProgress
                sx={{
                  color: theme.palette.secondary.main,
                  display: 'block',
                }}
                thickness={5.0}
                size="25px"
              />
            )}
          </ButtonCustom>
        </Grid>
      </Grid>
    </form>
  );
}
