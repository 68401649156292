import * as React from "react";

const StripeInput = React.forwardRef(
  (props, ref) => {
    const { component: Component, options, ...other } = props;
    const [mountNode, setMountNode] = React.useState(null);

    React.useImperativeHandle(
      ref,
      () => ({
        focus: () => mountNode.focus()
      }),
      [mountNode]
    );

    return (
        <div style={{marginTop:'4px', marginBottom:'-4px', width:'100%'}}>
            <Component
                onReady={setMountNode}
                options={{
                  ...options,
                }}
                {...other}
            />
        </div>
    );
  }
);

export default StripeInput;
