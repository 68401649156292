import React, { useRef, useImperativeHandle } from 'react'

const StripeInput = React.forwardRef(
    ({ component: Component, inputRef, options, ...props }, ref) =>
{
    const elementRef = useRef();
    useImperativeHandle(ref, () => ({
        focus: () => elementRef.current.focus
    }));
    return (
        <div style={{marginTop:'4px', marginBottom:'-4px', width:'100%'}}>
            <Component
                onReady={element => (elementRef.current = element)}
                options={options?options.options:null}

                {...props}
            />
        </div>
    )
});

export default StripeInput