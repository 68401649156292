import { Grid, Hidden, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import theme from '../../../../theme';
import ButtonCustom from '../../../atoms/Button';
import Input from '../animalBreed/Input';
import { animalsConfig } from '../animalName';
import { selectAnimals } from '../pricingSlice';

const useStyles = makeStyles((theme) => ({
  name_text: {
    overflow: 'auto',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
    },
  },
  icon: {
    marginTop: 20,
    height: 80,
    width: 80,
    marginRight: 40,
    [theme.breakpoints.down('lg')]: {
      height: 70,
      width: 70,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 30,
      marginLeft: 30,
      marginTop: 5,
    },
  },
  typo1: {
    fontSize: '1.3rem',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
    },
  },
  typo2: {
    fontSize: '1.2rem',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem',
    },
  },
  paper: {
    background: 'inherit',
    width: 150,
    height: 150,
    [theme.breakpoints.down('lg')]: {
      width: 140,
      height: 140,
    },
    [theme.breakpoints.down('md')]: {
      width: 130,
      height: 130,
    },
    [theme.breakpoints.down('sm')]: {
      width: 120,
      height: 120,
    },
  },
  select: {
    color: theme.palette.primary.main,
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },
    '&:before': {
      borderColor: theme.palette.primary.main,
    },
    '&:after': {
      borderColor: theme.palette.primary.main,
    },
  },
  firstInput: {
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 5,
    },
  },
  secondInput: {
    marginTop: 25,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
  tooltip: {
    fontSize: '1.1em',
  },
}));

export default function MultipleAnimal({
  handleNextStep,
  isLoading,
  breedList,
}) {
  const { t } = useTranslation(null, {
    keyPrefix: 'pricing.animalBreed',
  });
  const classes = useStyles();

  const dispatch = useDispatch();
  const { animals } = useSelector((state) => state.pricing);

  const setBreedId = (breedId, index) => {
    dispatch(
      selectAnimals(
        animals.map((animal, idx) => {
          if (idx === index) {
            return (animal = { ...animal, breed: breedId });
          }
          return animal;
        }),
      ),
    );
  };

  const onChange = (value, index) => {
    setBreedId(value, index);
    setTimeout(() => {
      if (window.innerWidth < 768)
        window.scrollTo({ top: 1500, behavior: 'smooth' });
    }, 500);
  };

  return (
    <>
      <Grid item xs={12} style={{ marginTop: '40px ' }}>
        {animals.map((animal, index) => (
          <Grid
            item
            xs={12}
            display="flex"
            direction="column"
            alignItems="center"
            style={{
              marginBottom: index < animals?.length - 1 ? '30px' : 0,
            }}
          >
            <Grid
              style={{ marginBottom: '10px', width: '100%' }}
              sx={{
                justifyContent: { xs: 'center', sm: 'flex-start' },
              }}
              display="flex"
              alignItems="center"
            >
              <Typography
                className={classes.name_text}
                color="primary"
              >
                {`${animal?.name
                  .charAt(0)
                  .toUpperCase()}${animal?.name.slice(1)}`}
              </Typography>
              <Hidden smUp>
                <img
                  className={classes.icon}
                  src={animalsConfig[animal?.species]?.icon}
                  alt={'img'}
                />
              </Hidden>
            </Grid>
            <Grid
              container
              xs={12}
              sx={{ alignItems: { xs: 'center', sm: 'flex-start' } }}
              justifyContent="center"
              alignItems="flex-start"
            >
              <Hidden smDown>
                <Grid xs={2}>
                  <img
                    className={classes.icon}
                    src={animalsConfig[animal?.species]?.icon}
                    alt={'img'}
                  />
                </Grid>
              </Hidden>
              <Grid xs={12} sm={10}>
                {animals[index] && (
                  <Input
                    classes={classes}
                    isMultiple={true}
                    breedList={breedList}
                    breed={animal.breed}
                    species={animal.species}
                    onChange={(value) => onChange(value, index)}
                    onCrossClick={(breedId) =>
                      onChange(breedId, index)
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          marginBottom: '20px',
          marginTop: { xs: '30px', md: '50px' },
        }}
      >
        <ButtonCustom
          onClick={() => handleNextStep(animals)}
          width={122}
          height={47}
          disabled={animals.find((animal) => !animal.breed)}
        >
          {!isLoading ? (
            t('nextButton')
          ) : (
            <CircularProgress
              sx={{
                color: theme.palette.secondary.main,
                display: 'block',
              }}
              thickness={5.0}
              size="25px"
            />
          )}
        </ButtonCustom>
      </Grid>
    </>
  );
}
