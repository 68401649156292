import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import pricing_asset_mobile_3 from '../../../../static/pricing/pricing-asset-mobile-3.svg';
import { blue } from '../../../../theme';
import Button from '../../../atoms/Button';
import Carousel from '../../../molecules/Carousel';
import { GUARD_TYPE_PREVENTION } from '../../pricing/constants';
import ItemAnimal from '../ItemAnimal';
import { updateAnimal } from '../refundSlice';

const useStyles = makeStyles((theme) => ({
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      color: 'white',
      backgroundImage: `url(${pricing_asset_mobile_3})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
}));

function Refund1(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.refunds.step1',
  });
  const classes = useStyles();
  const activeContractItems = props.data?.contracts
    ?.filter((contract) => !contract.isResiliated)
    .map((contract) => contract.items)[0];
  let animals = activeContractItems?.filter(
    (item) => item.contractItemType === 'health',
  );
  const quoteType = useSelector((state) => state.pricing.quoteType);

  if (props.guard) {
    if (quoteType === GUARD_TYPE_PREVENTION) {
      let ciPrev = activeContractItems
        .filter((item) => item.contractItemType === 'prev')
        .map((item) => item.animal.id);
      animals = animals.filter(
        (item) => !ciPrev.includes(item.animal.id),
      );
    } else {
      let ciGuards = activeContractItems
        .filter((item) => item.contractItemType === 'guard')
        .map((item) => item.animal.id);
      animals = animals.filter(
        (item) => !ciGuards.includes(item.animal.id),
      );
    }
  }

  const dispatch = useDispatch();
  const { status, typeClaim } = useSelector((state) => state.refund);
  const [puce, setPuce] = useState(false);
  const [puceNumber, setPuceNumber] = useState(false);
  const [selectedContractID, setSelectedContractID] = useState();
  const [selectedAnimalID, setSelectedAnimalID] = useState();
  const [selectedName, setSelectedName] = useState();
  const [selectedSpecie, setSelectedSpecie] = useState();
  const [error, setError] = useState('');

  const handleNext = (data) => {
    if (data.animal['puce'] || typeClaim === 'quote' || props.guard) {
      if (props.guard) {
        props.handleNext({ animals: [data.animal] });
      } else {
        props.handleNext(data.id);
      }
    } else {
      setPuce(true);
      setSelectedContractID(data.id);
      setSelectedAnimalID(data.animal['id']);
      setSelectedName(data.animal['name']);
      setSelectedSpecie(data.animal['species']);
    }
  };

  const handleChange = (event) => {
    const regex =
      selectedSpecie === 'cat'
        ? /(^\d{3}26\d{10}$)|(^\d{3}[A-Za-z]{3}$)|(^[A-Za-z]{3}\d{3}$)|(724\d{12}$)|(9\d{14}$)/gm
        : /(^\d{3}26\d{10}$)|(^\d{3}[A-Za-z]{3}$)|(^2[A-Za-z]{3}\d{3}$)|(724\d{12}$)|(9\d{14}$)/gm;

    if (!event.target.value.match(regex)) {
      setError(t('setErrorFormat'));
    } else {
      setError('');
      setPuceNumber(event.target.value);
    }
  };

  useEffect(() => {
    if (status === 'succeeded') {
      setPuce(false);
      props.handleNext(selectedContractID);
    }
  }, [status, selectedContractID, props]);

  return !props.guard && puce ? (
    <Box
      style={{
        width: '90%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        color={'primary'}
        variant={'h4'}
        className={classes.title_text}
      >
        {t('title', { selectedName })}
      </Typography>

      <TextField
        id="outlined-number"
        error={error !== ''}
        helperText={error}
        onChange={handleChange}
        label={t('labelPuce')}
        sx={{ display: 'flex', width: '250px', margin: 'auto' }}
      />
      {status === 'failed' ? (
        <Typography
          color={'primary'}
          variant={'h4'}
          className={classes.title_text}
        >
          {t('statusFailed')}
        </Typography>
      ) : status === 'pending' ? (
        <CircularProgress />
      ) : (
        <Box>
          <Button onClick={() => setPuce(false)}>
            {t('otherAnimalButton')}
          </Button>

          <Button
            sx={{ marginLeft: '8px' }}
            disabled={!puceNumber || error}
            onClick={() =>
              dispatch(
                updateAnimal({
                  id: selectedAnimalID,
                  puce: puceNumber,
                }),
              )
            }
          >
            {t('nextButton')}
          </Button>
        </Box>
      )}
    </Box>
  ) : (
    <Box
      style={{
        width: '90%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {animals && animals.length !== 0 ? (
        <>
          <Typography
            color={'primary'}
            variant={'h4'}
            className={classes.title_text}
          >
            {t('subtitle1')}
            <span style={{ display: 'inline-block' }}>
              {t('subtitle2')}
            </span>
          </Typography>

          <Carousel>
            {animals.map((item, index) => (
              <ItemAnimal
                data={{
                  img: item.animal.breed.species,
                  text: item.animal.name,
                  value: item,
                }}
                key={index}
                onClick={handleNext}
              />
            ))}
          </Carousel>
        </>
      ) : (
        <h2
          style={{
            fontFamily: 'Supply',
            color: blue,
            margin: 'auto',
          }}
        >
          {t('notEligible.text1')} <br />
          <br />
          {t('notEligible.text2')} <br />
          {t('notEligible.text3')} <br />
          {t('notEligible.text4')}
          <br />
          {t('notEligible.text5')}{' '}
        </h2>
      )}
    </Box>
  );
}

Refund1.componentName = 'stepLabels.companion';

export default Refund1;
