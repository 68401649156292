import { configureStore } from '@reduxjs/toolkit'
import {combineReducers} from "redux";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import pricingReducer from '../components/templates/pricing/pricingSlice'
import userReducer from '../components/templates/login/userSlice'
import refundReducer from '../components/templates/account/refundSlice'
import garantiesReducer from '../components/templates/account/garanties/garantiesSlice'
import signingReducer from '../components/templates/quote/signingSlice'


const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['signing', 'garanties'],
};

const reducers = combineReducers({
    pricing: pricingReducer,
    user: userReducer,
    refund: refundReducer,
    signing: signingReducer,
    garanties: garantiesReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
    reducer: persistedReducer
})
