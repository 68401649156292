import FavoriteIcon from '@mui/icons-material/Favorite';
import { MobileDatePicker } from '@mui/lab';
import {
  Box,
  Button,
  Hidden,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from 'use-hooks';

import { Context } from '../../../layouts/Quote';
import caniche from '../../../static/anims/caniche.json';
import chat_hamac from '../../../static/anims/chat-hamac.json';
import chien_grue from '../../../static/anims/chien-grue.json';
import coccinelle from '../../../static/anims/coccinelle.json';
import horloge from '../../../static/anims/horloge.json';
import landeau from '../../../static/anims/landeau.json';
import levitation_chat from '../../../static/anims/levitation-chat.json';
import home_elements_18 from '../../../static/home/elements/Fichier 18.svg';
import home_elements_19 from '../../../static/home/elements/Fichier 19.svg';
import home_elements_20 from '../../../static/home/elements/Fichier 20.svg';
import home_elements_21 from '../../../static/home/elements/Fichier 21.svg';
import home_elements_22 from '../../../static/home/elements/Fichier 22.svg';
import home_elements_23 from '../../../static/home/elements/Fichier 23.svg';
import home_elements_24 from '../../../static/home/elements/Fichier 24.svg';
import home_elements_25 from '../../../static/home/elements/Fichier 25.svg';
import home_elements_26 from '../../../static/home/elements/fichier-26.svg';
import home_elements_27 from '../../../static/home/elements/fichier-27.svg';
import home_elements_28 from '../../../static/home/elements/fichier-28.svg';
import home_elements_29 from '../../../static/home/elements/fichier-29.svg';
import home_elements_34 from '../../../static/home/elements/Fichier-34.svg';
import quote_1_fleche from '../../../static/quote/1-fleche.svg';
import quote_1_left from '../../../static/quote/1-left.svg';
import quote_1_right from '../../../static/quote/1-right.svg';
import quote_1_transition from '../../../static/quote/1-transition.svg';
import angel_img from '../../../static/quote/2-angel.svg';
import quote_3_background from '../../../static/quote/3-background.svg';
import quote_4_img from '../../../static/quote/4-img-new.svg';
import quote_4_rect from '../../../static/quote/4-rect.svg';
import quote_4_transition from '../../../static/quote/4-transition.svg';
import quote_5_transition from '../../../static/quote/5-transition.svg';
import quote_6_rect from '../../../static/quote/6-rect.svg';
import quote_6_transition from '../../../static/quote/6-transition.svg';
import quote_7_img2 from '../../../static/quote/7-img2.svg';
import quote_7_img from '../../../static/quote/7-img.svg';
import quote_7_transition from '../../../static/quote/7-transition.svg';
import quote_0_transition from '../../../static/quote/mobile/0-img.svg';
import mobile_0_title from '../../../static/quote/mobile/0-title.svg';
import mobile_2_square from '../../../static/quote/mobile/2-square.svg';
import mobile_3_text_1 from '../../../static/quote/mobile/3-text-1.svg';
import mobile_3_text_2 from '../../../static/quote/mobile/3-text-2.svg';
import mobile_4_dog from '../../../static/quote/mobile/4-dog.svg';
import mobile_4_transition from '../../../static/quote/mobile/4-transition-mobile.svg';
import mobile_5_transition from '../../../static/quote/mobile/5-transition-mobile.svg';
import mobile_6_title1 from '../../../static/quote/mobile/6-title1.svg';
import mobile_6_title2 from '../../../static/quote/mobile/6-title2.svg';
import mobile_6_transition from '../../../static/quote/mobile/6-transition-mobile.svg';
import mobile_7_img1 from '../../../static/quote/mobile/7-img1.svg';
import mobile_7_img2 from '../../../static/quote/mobile/7-img2.svg';
import mobile_7_transition from '../../../static/quote/mobile/7-transition-mobile.svg';
import mobile_4_dog_1 from '../../../static/quote/mobile/mobile-4-dog1.svg';
import { blue, blue_light, yellow } from '../../../theme';
import { isAlcampo } from '../../../utils/language';
import referenceIntersection from '../../../utils/referenceIntersection';
import HexagonalButton from '../../atoms/HexagonalButton';
import Widget from '../../atoms/Widget';
import Slider from '../../molecules/Slider';
import Footer from '../home/Footer';
import { GUARD } from '../pricing/constants';
import {
  getPricing,
  selectSupplement,
  updatePrice,
} from '../pricing/pricingSlice';
import AgeConfirmation from './ageConfirmation';
import { updateQuote } from './signingSlice';
import StripeProvider from './StripeProvider';
import Switch2 from './Switch2';

const refSize = 1920;
const refSizeMobile = 360;

const minZoomMobile = 400;
const minZoomDesktop = 1000;
const midZoomDesktop = 1500;
const maxZoomDesktop = 2100;

const zoomDesktop = midZoomDesktop / refSize;
const zoomMobileConsultPrice = 0.6;
const zoomMobileSelector = 0.4;

const heightDesktop = 880;
const heightMobile = 600;

const sectionZeroTopMargin = 250;

const hexagone =
  "path('M11.05,21.14C.89,35.86,0,66.62,0,66.62S4.49,99.37,14,111s51.83,19.84,51.83,19.84,46.24-11.23,52.85-15.85S136,69,136,69s-1.84-32.63-6.54-41.21C124.44,18.5,94,3,94,3L45.39,0')";

const useStyles = makeStyles((theme) => ({
  zoomHome: {
    height: '100%',
    [theme.breakpoints.between(minZoomMobile, minZoomDesktop)]: {
      zoom: (props) => props.zoom,
      '@supports ( -moz-appearance:none )': {
        '-moz-transform': (props) => `scale(${props.zoom})`,
        '-moz-transform-origin': '0 0',
        width: (props) => `${100 * (1 / props.zoom)}vw`,
      },
    },
    [theme.breakpoints.between(minZoomDesktop, midZoomDesktop)]: {
      zoom: (props) => props.zoom,
      '@supports ( -moz-appearance:none )': {
        '-moz-transform': (props) => `scale(${props.zoom})`,
        '-moz-transform-origin': '0 0',
        width: (props) => `${100 * (1 / props.zoom)}vw`,
      },
    },
    [theme.breakpoints.between(midZoomDesktop, maxZoomDesktop)]: {
      zoom: zoomDesktop,
      '@supports ( -moz-appearance:none )': {
        '-moz-transform': `scale(${zoomDesktop})`,
        '-moz-transform-origin': '0 0',
        width: `${100 * (1 / zoomDesktop)}vw`,
      },
    },
  },
  homeSection0: {
    position: 'relative',
    height: (props) => props.isGuardType,
    overflow: 'hidden',
    background: (props) =>
      `linear-gradient(${yellow} ${
        props.isGuardType ? 52 : 48
      }%, #ffffff ${props.isGuardType ? 52 : 48}%)`,
    zIndex: 0,
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: (props) =>
        props.isGuardType ? 1670 : heightDesktop * 2,
      '@supports (-webkit-appearance:none) and (stroke-color:transparent)':
        {
          height: (props) =>
            props.isGuardType ? 1670 + 50 : heightDesktop * 2 + 50,
        },
      background: (props) =>
        `linear-gradient(${yellow} ${
          props.isGuardType ? 47 : 45
        }%, #ffffff ${props.isGuardType ? 47 : 45}%)`,
    },
    [theme.breakpoints.between(minZoomDesktop, maxZoomDesktop)]: {
      paddingTop: sectionZeroTopMargin,
      height: (props) =>
        props.isGuardType
          ? 1900
          : heightDesktop * 2 + sectionZeroTopMargin + 180,
      '@supports (-webkit-appearance:none) and (stroke-color:transparent)':
        {
          height: (props) =>
            props.isGuardType
              ? 1900 + 50
              : heightDesktop * 2 + sectionZeroTopMargin + 80,
        },
      background: (props) =>
        `linear-gradient(${yellow} ${
          props.isGuardType ? 53 : 50
        }%, #ffffff ${props.isGuardType ? 53 : 50}%)`,
    },
  },
  firstHomeSection: {
    position: 'relative',
    [theme.breakpoints.down(minZoomDesktop, maxZoomDesktop)]: {
      marginTop: 50,
    },

    height: (props) => (props.isGuardType ? 1170 : 1650),
    overflow: 'hidden',
    background: (props) =>
      `linear-gradient(${yellow} ${
        props.isGuardType ? 52 : 46.5
      }%, #ffffff ${props.isGuardType ? 52 : 46.5}%)`,
    zIndex: 0,
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: (props) =>
        props.isGuardType ? 1670 : heightDesktop * 2 + 250,
      '@supports (-webkit-appearance:none) and (stroke-color:transparent)':
        {
          height: (props) =>
            props.isGuardType ? 1670 + 50 : heightDesktop * 2 + 50,
        },
      background: (props) =>
        `linear-gradient(${yellow} ${
          props.isGuardType ? 47 : 45
        }%, #ffffff ${props.isGuardType ? 47 : 45}%)`,
    },
    [theme.breakpoints.between(minZoomDesktop, maxZoomDesktop)]: {
      paddingTop: sectionZeroTopMargin,
      height: (props) =>
        props.isGuardType
          ? 1900
          : heightDesktop * 2 + sectionZeroTopMargin + 850,
      '@supports (-webkit-appearance:none) and (stroke-color:transparent)':
        {
          height: (props) =>
            props.isGuardType
              ? 1900 + 50
              : heightDesktop * 2 + sectionZeroTopMargin + 80,
        },
      background: (props) =>
        `linear-gradient(${yellow} ${
          props.isGuardType ? 53 : 47
        }%, #ffffff ${props.isGuardType ? 53 : 47}%)`,
    },
  },
  homeSection2: {
    position: 'relative',
    height: 650,
    overflow: 'hidden',
    background: blue_light,
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: heightDesktop,
    },
  },
  homeSection4: {
    position: 'relative',
    height: 2 * heightMobile + 15,
    overflow: 'hidden',
    background: `linear-gradient(${blue_light} 1%, #9EBECC 1%)`,
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: 2 * heightDesktop,
      background: '#9EBECC',
    },
  },
  homeSection5: {
    position: 'relative',
    height: 2 * heightMobile,
    overflow: 'hidden',
    background: blue_light,
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: 3 * heightDesktop,
    },
  },
  homeSection6: {
    position: 'relative',
    height: 2 * heightMobile,
    overflow: 'hidden',
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: 2 * heightDesktop,
    },
  },
  homeSection7: {
    position: 'relative',
    height: 650,
    overflow: 'hidden',
    background: `linear-gradient(${blue_light} 92%, #ffffff 92%)`,
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: 990,
      background: `linear-gradient(${blue_light} 89%, #ffffff 89%)`,
    },
  },
  homeSection8: {
    background: '#ffffff',
    position: 'relative',
    height: 550,
    overflow: 'hidden',
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: 770,
    },
  },
  sectionZeroTopMarginClass: {
    [theme.breakpoints.between(minZoomDesktop, maxZoomDesktop)]: {
      marginTop: sectionZeroTopMargin,
    },
  },
  sectionZeroTopMarginClass1: {
    [theme.breakpoints.between(minZoomDesktop, maxZoomDesktop)]: {
      marginTop: sectionZeroTopMargin + 110,
    },
  },
  homeSection0Title: {
    [theme.breakpoints.between(minZoomDesktop, maxZoomDesktop)]: {
      marginBottom: 40,
      marginTop: -sectionZeroTopMargin + 50,
    },
  },
  homeSection0SubTitle: {
    [theme.breakpoints.between(minZoomDesktop, maxZoomDesktop)]: {
      marginBottom: 80,
    },
  },
  homeSection0AlreadyInsured: {
    [theme.breakpoints.between(minZoomDesktop, maxZoomDesktop)]: {
      marginTop: sectionZeroTopMargin,
      marginBottom: '40px',
    },
  },
  imgLeft: {
    width: '768px',
    position: 'absolute',
    top: 300,
    left: -70,
    [theme.breakpoints.up(maxZoomDesktop)]: {
      top: 110,
    },
  },
  imgRight: {
    width: '725px',
    position: 'absolute',
    top: 250,
    right: 0,
    [theme.breakpoints.up(maxZoomDesktop)]: {
      top: 100,
    },
  },
  imgCenter: {
    [theme.breakpoints.between(minZoomMobile, minZoomDesktop)]: {
      transform: ({ zoom }) =>
        `translateX(calc(${50 / zoom}vw - ${refSizeMobile / 2}px))`,
    },
    [theme.breakpoints.down(minZoomMobile)]: {
      transform: `translateX(calc((100vw - ${refSizeMobile}px)/2))`,
    },
    [theme.breakpoints.between(minZoomDesktop, midZoomDesktop)]: {
      transform: 'translateX(0px)',
    },
    [theme.breakpoints.between(midZoomDesktop, maxZoomDesktop)]: {
      transform: `translateX(calc(${100 / zoomDesktop / 2}vw - ${
        refSize / 2
      }px ))`,
      '@supports (-webkit-appearance:none) and (stroke-color:transparent)':
        {
          transform: `translateX(calc(${100 / 2}vw - ${
            refSize / 2
          }px ))`,
        },
    },
    [theme.breakpoints.up(maxZoomDesktop)]: {
      transform: `translateX(calc(${100 / 2}vw - ${refSize / 2}px ))`,
    },
  },
  displayMobileDesktop: {
    display: `block`,
    [theme.breakpoints.up(minZoomDesktop)]: {
      display: `none`,
    },
  },
  displayDesktopMobile: {
    display: `inline`,
    [theme.breakpoints.down(minZoomDesktop)]: {
      display: `none`,
    },
  },
  date_picker: {
    width: 270,
    height: 70,
    color: theme.palette.primary.main,
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
    fontSize: 25,
  },
  text_field_label: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  text_field_label_disabled: {
    fontSize: 18,
    color: 'rgba(0, 0, 0, 0.38) !important',
  },
  levitation_chat_desktop: {
    top: 400,
    right: 400,
    width: 378,
    position: 'absolute',
    [theme.breakpoints.up(maxZoomDesktop)]: {
      top: 200,
    },
  },
  levitation_chat_mobile: {
    width: '200px',
    top: 280,
    left: 20,
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      left: 0,
      top: 350,
      width: '150px',
    },
  },
  caniche_desktop: {
    top: 350,
    right: 0,
    width: 422,
    position: 'absolute',
    [theme.breakpoints.up(maxZoomDesktop)]: {
      top: 150,
    },
  },
  caniche_mobile: {
    width: '200px',
    top: 280,
    right: 0,
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      top: 340,
      width: '150px',
    },
  },
  chat_hamac_desktop: {
    top: 525,
    left: 0,
    width: '750px',
    position: 'absolute',
    [theme.breakpoints.up(maxZoomDesktop)]: {
      top: 305,
    },
  },
  horloge_desktop: {
    top: 350,
    left: 100,
    width: '150px',
    position: 'absolute',
    [theme.breakpoints.up(maxZoomDesktop)]: {
      top: 180,
    },
  },
  strikethrough: {
    fontSize: '28px',
    '&::before': {
      top: '28%',
      left: '25%',
      width: '50%',
      height: '5px',
      content: '""',
      display: 'block',
      opacity: '0.7',
      position: 'absolute',
      transform: 'rotate(10deg) translateY(-0%)',
      background: 'red',
    },
  } /* Strikethrough */,

  strikethrougha: {
    display: 'inline-flex',
    position: 'relative',
    transition: 'all 0.1s cubic-bezier(.55, 0, .1, 1)',

    '&::after': {
      content: "''",
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '4px',
      boxShadow: '0 1px rgba(255,255,255,0.6)',
      marginTop: '0.3em',
      background: 'red',
      opacity: '0.7',
      transformOrigin: 'center left',
      animation: `$strikethrougha 1s 0.5s cubic-bezier(.55, 0, .1, 1) 1`,
      transition: 'transform 0.5s cubic-bezier(.55, 0, .1, 1)',
    },
  } /* Keyframes for initial animation */,
  '@keyframes strikethrougha': {
    '0%': {
      transform: 'scaleX(0)',
    },
    '100%': {
      transform: 'scaleX(1)',
    },
  },
  text_field: {
    maxWidth: 160,
    fontSize: 21,
    color: theme.palette.primary.main,
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

function QuotePage() {
  const { t } = useTranslation(null, {
    keyPrefix: 'quote',
  });
  const period = 'monthly';
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { status_docs } = useSelector((state) => state.signing);
  const url = new URLSearchParams(location?.search);
  const urlQuoteId = url?.get('quoteId');

  const notif = React.useCallback(
    (msg) => {
      enqueueSnackbar(msg, { variant: 'success' });
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    if (!_.isEmpty(urlQuoteId)) {
      dispatch(getPricing(urlQuoteId));
    }
  }, [dispatch, urlQuoteId]);
  const { width } = useWindowSize();
  const [openModal, closeModal] = useContext(Context);
  const zoom =
    width < minZoomDesktop
      ? 1 + (width - minZoomMobile) / 900
      : width / refSize;

  const {
    quoteId,
    prices,
    firstName,
    animals,
    rates,
    birthday,
    animalType,
    hasFormerContract,
    affiliate,
    availableGuarantees,
    start,
  } = useSelector((state) => state.pricing);

  const isGuardType = animalType === GUARD;
  const animalName = animals[0]?.name || 'Bibou';
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const isVisible1 = referenceIntersection(ref1);
  const isVisible2 = referenceIntersection(ref2);
  const isVisible3 = referenceIntersection(ref3);

  const [userBirthdayDate, setUserBirthdayDate] = useState(null);
  const [ceil, setCeil] = useState(0);
  const [refund, setRefund] = useState(0);
  const [excess, setExcess] = useState(0);
  const [annuity, setAnnuity] = useState(0);
  const [prev, setPrev] = useState(0);
  const [option, setOption] = useState(false);

  const scrollTo = (sectionNumber) => {
    const offsetWidth = document.body.offsetWidth;
    let sectionOffset;
    switch (sectionNumber) {
      case 1:
        if (offsetWidth >= 2100) sectionOffset = 910;
        else if (offsetWidth > 1550) sectionOffset = 870;
        else if (offsetWidth >= 1000) sectionOffset = 1110 * zoom;
        // Mobile version
        else sectionOffset = 620 * zoom;
        break;
      default:
        sectionOffset = null;
    }
    if (!_.isNil(sectionOffset) && !_.isNaN(sectionOffset))
      window.scrollTo({
        top: sectionOffset,
        behavior: 'smooth',
      });
  };

  const normalizedPrices = useMemo(() => {
    let results = [];
    if (prices) {
      if (prices.prev) {
        for (const [prevKey, prevValue] of Object.entries(
          prices.prev,
        )) {
          results.push({
            type: 'prev',
            price: prevValue,
            rates: {
              prev: parseInt(prevKey),
            },
          });
        }
      }
      if (prices.base) {
        for (const [refundKey, refundValue] of Object.entries(
          prices.base.total[period],
        )) {
          for (const [excessKey, excessValue] of Object.entries(
            refundValue,
          )) {
            for (const [ceilKey, ceilValue] of Object.entries(
              excessValue,
            )) {
              results.push({
                type: 'health',
                price: ceilValue,
                rates: {
                  refund: parseInt(refundKey) / 100.0,
                  excess: parseInt(excessKey),
                  ceil: parseInt(ceilKey),
                },
              });
              if (prices.option) {
                const option_price =
                  prices.option.total[period][refundKey][excessKey][
                    ceilKey
                  ];
                for (const [
                  annuityKey,
                  annuityValue,
                ] of Object.entries(option_price)) {
                  if (
                    results.findIndex(
                      (e) =>
                        e.type === 'guard' &&
                        parseInt(annuityKey) === e.rates.annuity,
                    ) === -1
                  ) {
                    results.push({
                      type: 'guard',
                      price:
                        Math.round(
                          (annuityValue -
                            ceilValue +
                            Number.EPSILON) *
                            100,
                        ) / 100,
                      rates: {
                        annuity: parseInt(annuityKey),
                      },
                    });
                  }
                }
              }
            }
          }
        }
      } else {
        for (const [annuityKey, annuityValue] of Object.entries(
          prices.total[period],
        )) {
          results.push({
            type: 'guard',
            price: annuityValue,
            rates: {
              annuity: parseInt(annuityKey),
            },
          });
        }
      }
    }
    return results;
  }, [prices]);

  const {
    prev: prevChoices,
    refund: refundChoices,
    excess: excessChoices,
    ceil: ceilChoices,
    annuity: annuityChoices,
  } = useMemo(() => {
    const choices = {
      prev: [],
      refund: [],
      excess: [],
      ceil: [],
      annuity: [],
    };
    for (const price of normalizedPrices) {
      for (const rate in choices) {
        if (
          price.rates[rate] !== undefined &&
          !choices[rate].includes(price.rates[rate])
        ) {
          choices[rate].push(price.rates[rate]);
        }
      }
    }
    return choices;
  }, [normalizedPrices]);

  const formulaChoices = useMemo(() => {
    const formulas = isAlcampo()
      ? ['Mascota', 'SuperMascota', 'HiperMascota']
      : ['Light', 'Confort', 'Premium'];
    if (
      refundChoices.length === 3 &&
      excessChoices.length === 3 &&
      ceilChoices.length === 3 &&
      prevChoices.length === 3
    ) {
      return formulas.map((name, index) => ({
        id: index,
        name: name,
        refund: refundChoices[index],
        excess: excessChoices[index],
        ceil: ceilChoices[index],
        prev: prevChoices[index],
      }));
    } else {
      return [];
    }
  }, [prevChoices, refundChoices, excessChoices, ceilChoices]);

  const activeFormula = useMemo(() => {
    return formulaChoices.find(
      (e) =>
        e.refund === refund &&
        e.excess === excess &&
        e.ceil === ceil &&
        e.prev === prev,
    );
  }, [formulaChoices, refund, excess, ceil, prev]);

  const userAge = useMemo(() => {
    const now = new Date();
    if (!userBirthdayDate || now <= userBirthdayDate) {
      return 0;
    } else {
      return (
        new Date(
          now.getTime() - userBirthdayDate.getTime(),
        ).getFullYear() - 1970
      );
    }
  }, [userBirthdayDate]);

  const isAgeValid = userAge >= 18 && userAge <= 80;

  useEffect(() => {
    if (option && isAgeValid) {
      let offsetBirthDate = new Date(userBirthdayDate);
      offsetBirthDate.setHours(userBirthdayDate.getHours() + 7);
      dispatch(
        updatePrice({
          id: quoteId,
          birthday: offsetBirthDate.toISOString().split('T')[0],
        }),
      );
    }
  }, [userBirthdayDate, isAgeValid, dispatch, option, quoteId]);

  useEffect(() => {
    if (_.isNil(birthday)) {
      setUserBirthdayDate(null);
      return;
    }
    const birth = new Date(birthday);
    setUserBirthdayDate(birth);
  }, [birthday]);

  const guardPrice = useMemo(() => {
    let result = 0;
    if (option && isAgeValid) {
      result =
        normalizedPrices.find(
          (e) => e.type === 'guard' && e.rates?.annuity === annuity,
        )?.price || 0;
    }
    dispatch(selectSupplement(result));
    return result;
  }, [annuity, normalizedPrices, option, isAgeValid, dispatch]);

  const totalPrice = useMemo(() => {
    const healthPrice =
      normalizedPrices.find(
        (e) =>
          e.type === 'health' &&
          e.rates?.ceil === ceil &&
          e.rates?.refund === refund &&
          e.rates?.excess === excess,
      )?.price || 0;
    const prevPrice =
      normalizedPrices.find(
        (e) => e.type === 'prev' && e.rates?.prev === prev,
      )?.price || 0;
    return (
      Math.round(
        (healthPrice + guardPrice + prevPrice + Number.EPSILON) * 100,
      ) / 100
    );
  }, [refund, excess, prev, ceil, normalizedPrices, guardPrice]);

  const displayTotalPrice = totalPrice.toFixed(2).replace('.', ',');

  const newRates = useMemo(() => {
    if (isGuardType) {
      return {
        annuity: annuity.toString(),
      };
    } else {
      return {
        recursivity: period,
        annuity: annuity.toString(),
        franchise: excess.toString(),
        ceil: ceil.toString(),
        option: option ? 'option' : 'base',
        remb: Math.floor(refund * 100).toString(),
        prevention: prev,
      };
    }
  }, [refund, prev, option, ceil, excess, annuity, isGuardType]);

  useEffect(() => {
    if (_.isEmpty(rates)) return;

    if (rates.remb !== undefined) {
      setRefund(parseInt(rates.remb) / 100.0);
    }
    if (rates.prevention !== undefined) {
      setPrev(parseInt(rates.prevention));
    }
    if (rates.ceil !== undefined) {
      setCeil(parseInt(rates.ceil));
    }
    if (rates.franchise !== undefined) {
      setExcess(parseInt(rates.franchise));
    }
    if (rates.option !== undefined) {
      setOption(rates.option === 'option');
    }
    if (rates.annuity !== undefined) {
      setAnnuity(parseInt(rates.annuity));
    }
  }, [rates]);

  const changeRateOrFormula = useCallback(
    (rate, value) => {
      if (isAlcampo()) {
        const formula = formulaChoices.find((e) => e[rate] === value);
        setRefund(formula.refund);
        setExcess(formula.excess);
        setCeil(formula.ceil);
        setPrev(formula.prev);
      } else {
        switch (rate) {
          case 'prev':
            setPrev(value);
            break;
          case 'refund':
            setRefund(value);
            break;
          case 'ceil':
            setCeil(value);
            break;
          case 'excess':
            setExcess(value);
            break;
          default:
            console.error(`rate ${rate} unknown.`);
        }
      }
    },
    [formulaChoices],
  );

  // minimum entre maintenant +3 mois (+7 ans +8 ans du plus vieil animal)
  // 3 MOIS
  const minStartDate = React.useMemo(() => new Date(), []);

  const maxStartDate = useMemo(() => {
    let maxDates = (animals || []).map((animal) => {
      let animalDate = new Date(animal.birthday);
      animalDate.setFullYear(
        animalDate.getFullYear() + (isAlcampo() ? 6 : 8),
      );
      animalDate.setDate(animalDate.getDate() - 1);
      return animalDate;
    });
    let todayPlusThreeMonths = new Date();
    todayPlusThreeMonths.setMonth(
      todayPlusThreeMonths.getMonth() + 3,
    );
    maxDates.push(todayPlusThreeMonths);
    return new Date(Math.min(...maxDates));
  }, [animals]);

  const classes = useStyles({ zoom, isGuardType });

  const resultPrice = (
    <Button
      variant={'contained'}
      disabled={true}
      style={{
        padding: 0,
        width: '137px',
        height: '132px',
        clipPath: hexagone,
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Box
        style={{
          WebkitTextSizeAdjust: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant={'h5'}
          color={'secondary'}
          width={'100%'}
          style={{
            textTransform: 'lowercase',
            fontSize: displayTotalPrice.length > 6 ? '25px' : '29px',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {displayTotalPrice}
          <span style={{ marginLeft: '5px' }}>€</span>
        </Typography>
        <Typography
          variant={'h5'}
          color={'secondary'}
          width={'max-content'}
          style={{
            textTransform: 'lowercase',
            fontSize: '19.25px',
          }}
          sx={{
            mt: { xs: '7px', md: 0 },
          }}
        >
          {t('perMonth')}
        </Typography>
      </Box>
    </Button>
  );

  const supplementPrice = () => (
    <Button
      disabled={true}
      variant={'contained'}
      style={{
        width: '137px',
        height: '132px',
        backgroundColor: theme.palette.primary.main,
        clipPath: hexagone,
        transform: 'scale(1.0814)',
        WebkitTextSizeAdjust: 'auto',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Typography
          variant={'h5'}
          color={'secondary'}
          width={'100%'}
          style={{
            textTransform: 'lowercase',
            fontSize: '17.8px',
            lineHeight: '45px',
            marginTop: -20,
          }}
        >
          {t('extraCharge')}
          <br />
        </Typography>
        <Typography
          variant={'h5'}
          color={'secondary'}
          width={'100%'}
          style={{ textTransform: 'lowercase', fontSize: '30px' }}
        >
          +
          {option && isAgeValid
            ? guardPrice.toFixed(2).replace('.', ',')
            : '0'}
          <span style={{ marginLeft: '5px' }}>€</span>
        </Typography>
      </Box>
    </Button>
  );

  const chooseOffer = (marginAuto = false) => (
    <>
      <Button
        variant={'contained'}
        style={{
          width: '337px',
          height: '62px',
          clipPath:
            'polygon(2px 0, 0 62px, 337px 60px, 330px 3px, 2px 0)',
          borderRadius: 0,
        }}
        onClick={() => {
          validateOffer();
        }}
      >
        <Typography
          variant={'h5'}
          color={'secondary'}
          width={'100%'}
          style={{
            textTransform: 'lowercase',
            fontSize: '24.22px',
          }}
        >
          {t('chooseOffer')}
        </Typography>
      </Button>
      <Typography
        onClick={() => {
          if (status_docs !== 'loading') {
            notif(t('resendedQuote'));
            dispatch(
              updateQuote({
                id: quoteId,
                resend: true,
                rates: newRates,
              }),
            );
          }
        }}
        disabled={status_docs === 'loading'}
        variant={'h6'}
        sx={{
          width: 'fit-content',
          margin: {
            xs: 'auto',
            md: marginAuto ? 'auto' : '0px 0px 0px 2%',
          },
          cursor: status_docs !== 'loading' ? 'pointer' : 'wait',
          mt: 1,
          textDecoration: 'underline',
          color:
            status_docs !== 'loading'
              ? theme.palette.primary.main
              : 'gray',
        }}
      >
        {t('resendQuote')}
      </Typography>
    </>
  );

  const chooseOfferMobile = (
    <>
      <Button
        variant={'contained'}
        style={{
          width: '170px',
          height: '28px',
          clipPath:
            'polygon(3px 1px, 0 28px, 170px 27px, 165px 0, 3px 1px)',
          borderRadius: 0,
          padding: 0,
          backgroundColor: theme.palette.primary.main,
        }}
        onClick={() => {
          validateOffer();
        }}
      >
        <Typography
          variant={'h5'}
          color={'secondary'}
          width={'100%'}
          style={{
            textTransform: 'lowercase',
            fontSize: '13px',
          }}
        >
          {t('chooseOffer')}
        </Typography>
      </Button>
      <Typography
        onClick={() => {
          if (status_docs !== 'loading') {
            notif(t('notifQuoteSend'));
            dispatch(
              updateQuote({
                id: quoteId,
                resend: true,
                rates: newRates,
              }),
            );
          }
        }}
        disabled={status_docs === 'loading'}
        variant={'h6'}
        sx={{
          width: 'fit-content',
          margin: 'auto',
          cursor: status_docs !== 'loading' ? 'pointer' : 'wait',
          mt: 1,
          textDecoration: 'underline',
          color:
            status_docs !== 'loading'
              ? theme.palette.primary.main
              : 'gray',
        }}
      >
        {t('resendQuote')}
      </Typography>
    </>
  );

  const chooseOfferExtended = (
    <Button
      variant={'contained'}
      style={{
        width: '380px',
        height: '97.5185px',
        clipPath:
          'polygon(1.28px 0.27px, 0 93.7px, 380.03px 97.52px, 378.79px 0, 1.28px 0.27px)',
        backgroundColor: theme.palette.primary.main,
      }}
      onClick={() => {
        validateOffer();
      }}
    >
      <Typography
        variant={'h5'}
        color={'secondary'}
        width={'100%'}
        style={{
          textTransform: 'lowercase',
        }}
      >
        {t('chooseOffer')} <br />
        {displayTotalPrice} € {t('perMonth')}
      </Typography>
    </Button>
  );

  const chooseOfferExtendedMobile = (
    <Button
      variant={'contained'}
      style={{
        width: '170px',
        height: '36px',
        clipPath:
          'polygon(0 0, 0 36px, 170px 34.5px, 167px 1.5px, 0 0)',
        borderRadius: 0,
        padding: 0,
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Typography
        variant={'h5'}
        color={'secondary'}
        width={'100%'}
        style={{
          textTransform: 'lowercase',
        }}
        onClick={() => {
          validateOffer();
        }}
      >
        {t('chooseOffer')} <br />
        {t('atOfferPerMonth', { price: displayTotalPrice })}
      </Typography>
    </Button>
  );

  const openStripeModal = () => {
    openModal(
      <StripeProvider
        affiliate={affiliate?.free_months}
        guard={isGuardType}
        resiliation={hasFormerContract}
        quoteID={quoteId}
        rates={newRates}
        price={totalPrice}
        period={period}
      />,
    );
  };

  const handleAgeClose = (birthdayDate) => {
    if (birthdayDate) {
      setUserBirthdayDate(birthdayDate);
      setOption(true);
    } else {
      setUserBirthdayDate(null);
      setOption(false);
    }
    closeModal();
  };

  const openAgeConfirmationModal = (isModification = false) => {
    openModal(
      <AgeConfirmation
        close={handleAgeClose}
        defaultBirthdayDate={userBirthdayDate}
        isModification={isModification}
        setUserBirthdayDate={setUserBirthdayDate}
      />,
      {
        clipPath: { xs: 'polygon(1% 5%,100% 0%,98% 100%,0% 98%)' },
        width: { xs: '100%', sm: '600px', md: '700px' },
        height: { xs: '450px', md: '600px' },
        display: 'flex',
        flexFlow: 'column',
      },
    );
  };

  const validateOffer = () => {
    if (!isGuardType && option && !isAgeValid) {
      openAgeConfirmationModal();
      return;
    }
    openStripeModal();
  };

  const modalStyle = {
    clipPath: { xs: 'polygon(1% 5%,100% 0%,98% 100%,0% 98%)' },
    width: { xs: '700px' },
    height: { xs: 'fit-content' },
    display: 'flex',
    flexFlow: 'column',
  };

  const openDeathInfo = () => {
    openModal(
      <Widget color="white" modal>
        <Typography
          color="primary"
          variant="h6"
          sx={{
            fontSize: { xs: '15px', md: '20px' },
            marginBottom: '20px',
          }}
        >
          {isGuardType
            ? t('openDeathInfo.isGuardText1')
            : t('openDeathInfo.isNotGuardText1')}
          <span style={{ display: 'inline-block' }}>
            {t('openDeathInfo.perAnimal')}
          </span>
          <br />
          {isGuardType
            ? t('openDeathInfo.isGuardText2')
            : t('openDeathInfo.isNotGuardText2')}
          <span style={{ display: 'inline-block' }}>
            {t('openDeathInfo.andSoOn')}
          </span>
        </Typography>
      </Widget>,
      modalStyle,
    );
  };

  return (
    <Box className={classes.zoomHome}>
      <section
        id={'section0-quote'}
        ref={ref1}
        className={classes.firstHomeSection}
      >
        <Box
          sx={{
            right: 150,
            bottom: 150,
            display:
              affiliate?.free_months > 0 && width >= 1000
                ? 'block'
                : 'none',
          }}
          position={'absolute'}
        >
          <Typography
            variant="h5"
            sx={{
              padding: { xs: '8px', md: '15px' },
              fontSize: '30px',
              transform: 'rotate(11deg)',
            }}
            style={{
              backgroundImage: `url(${home_elements_34})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >
            {affiliate?.name} {t('offerYou')} {affiliate?.free_months}{' '}
            {t('month')}
          </Typography>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Box
            position={'absolute'}
            style={{
              left: -283.344,
              top: 751.1586,
              width: '2794.122px',
            }}
            className={classes.sectionZeroTopMarginClass}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img src={quote_1_transition} alt="transition" />
            </div>
          </Box>

          <div className={classes.imgLeft}>
            <img src={quote_1_left} alt="dog" />
          </div>

          <div className={classes.imgRight}>
            <img src={quote_1_right} alt="cat" />
          </div>
          <Lottie
            animationData={horloge}
            play={isVisible1}
            loop={true}
            className={classes.horloge_desktop}
          />
          <Lottie
            animationData={chat_hamac}
            play={isVisible1}
            loop={true}
            className={classes.chat_hamac_desktop}
          />
          <Lottie
            animationData={levitation_chat}
            play={isVisible1}
            loop={true}
            className={classes.levitation_chat_desktop}
          />
          <Lottie
            animationData={caniche}
            play={isVisible1}
            loop={true}
            className={classes.caniche_desktop}
          />
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Box
            position={'absolute'}
            style={{
              zIndex: -1,
              left: -30,
              top: 340,
              width: '481px',
            }}
          >
            <div className={classes.imgCenter}>
              <img src={quote_0_transition} alt="transition" />
            </div>
          </Box>
          <Lottie
            animationData={levitation_chat}
            play={isVisible1}
            loop={true}
            className={classes.levitation_chat_mobile}
          />
          <Lottie
            animationData={caniche}
            play={isVisible1}
            loop={true}
            className={classes.caniche_mobile}
          />
        </Box>
        <Box sx={{ mt: { xs: '67px', md: '140px' } }}>
          <Typography
            sx={{
              lineHeight: { xs: '20px', md: '40px' },
            }}
            variant="h2"
            className={classes.homeSection0Title}
          >
            {firstName},
            <span className={classes.displayDesktopMobile}>
              &nbsp;
            </span>
            <span className={classes.displayMobileDesktop} />
            {t('quoteHere')}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              mt: { xs: '19px', md: '5px' },
              lineHeight: { xs: '14.4px', md: '40px' },
            }}
            className={classes.homeSection0SubTitle}
          >
            {t('littlePrice')}
            <span className={classes.displayDesktopMobile}>
              &nbsp;
            </span>
            <span className={classes.displayMobileDesktop} />
            {animals?.length > 1
              ? t('your') + animals?.length + t('companions')
              : t('cuteCompanion')}
            <FavoriteIcon
              sx={{
                fontSize: { xs: '13px', md: 'inherit' },
                transform: {
                  xs: 'translateY(2px)',
                  md: 'translateY(5px)',
                },
              }}
              style={{
                marginLeft: '12px',
                color: '#d32d00',
              }}
            />
          </Typography>
          <Box
            sx={{
              mt: { xs: '36px', md: '165px' },
              width: { xs: '100%', md: '100%' },
            }}
            className={classes.homeSection0AlreadyInsured}
          >
            <Button
              onClick={() => {
                validateOffer();
              }}
              sx={{
                width: { xs: '100%', md: '100%' },
                zoom: { xs: zoomMobileConsultPrice, md: 1 },
                '@supports ( -moz-appearance:none )': {
                  '-moz-transform': {
                    xs: `scale(${zoomMobileConsultPrice})`,
                    md: 'scale(1)',
                  },
                },
              }}
            >
              {resultPrice}
            </Button>
          </Box>
          <Box
            sx={{
              mt: { xs: '10px', md: '0px' },
              width: { xs: '100%', md: '100%' },
            }}
          >
            <Box
              sx={{
                width: { xs: '100%', md: '100%' },
                zoom: { xs: zoomMobileConsultPrice, md: 1 },
                '@supports ( -moz-appearance:none )': {
                  '-moz-transform': {
                    xs: `scale(${zoomMobileConsultPrice})`,
                    md: 'scale(1)',
                  },
                },
              }}
            >
              <MobileDatePicker
                views={['year', 'month', 'day']}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    InputLabelProps={{
                      className: classes.text_field_label,
                    }}
                    label={t('mobileDatePicker.label')}
                  />
                )}
                cancelText={t('mobileDatePicker.cancelText')}
                okText={t('mobileDatePicker.okText')}
                onChange={(date) => {
                  dispatch(
                    updatePrice({
                      id: quoteId,
                      start:
                        date &&
                        date.toISOString().replace('Z', '+00:00'),
                    }),
                  );
                }}
                showToolbar={false}
                minDate={minStartDate}
                maxDate={maxStartDate}
                InputProps={{ className: classes.date_picker }}
                value={start}
              />
            </Box>
          </Box>
          <Box
            sx={{ display: { xs: 'block', md: 'none' } }}
            style={{ marginTop: 13, width: '100%' }}
          >
            {chooseOfferMobile}
          </Box>
          <Box
            sx={{ display: { xs: 'none', md: 'block' } }}
            style={{ marginTop: 20, width: '100%' }}
          >
            {chooseOffer(true)}
          </Box>
          <Box
            sx={{
              mt: { xs: '12px', md: '23.04px' },
              width: { xs: '100%', md: '100%' },
            }}
          >
            <Typography
              variant={'h6'}
              width={'100%'}
              sx={{
                fontSize: { xs: '12px', md: '20px' },
              }}
            >
              {t('addOffer')}
            </Typography>
          </Box>
          <Box
            sx={{
              mt: { xs: '52px', md: '30px' },
              width: { xs: '100%', md: '100%' },
            }}
          >
            <Box
              component="img"
              alt="svg"
              src={quote_1_fleche}
              style={{ cursor: 'pointer', zIndex: 100 }}
              sx={{
                width: { xs: '25px', md: '70px' },
                height: { xs: '14px', md: '39px' },
              }}
              onClick={() => scrollTo(1)}
            />
          </Box>
          <Box
            sx={{
              marginTop: { xs: '210px', md: '250px' },
              width: '100%',
              position: 'relative',
              '@supports (-moz-appearance:none)': {
                marginTop: {
                  xs: '200px !important',
                  xl: '250px !important',
                },
              },
            }}
          >
            <Box
              position={'absolute'}
              sx={{
                justifyContent: 'center',
                width: '100%',
                display: { xs: 'flex', md: 'none' },
              }}
            >
              <div style={{ width: '268px' }}>
                <img src={mobile_0_title} alt="transition" />
              </div>
            </Box>
            <Box
              position={'relative'}
              width={'100%'}
              paddingTop={
                !isGuardType ? { xs: '1%', md: '4%' } : '6px'
              }
              className={classes.sectionZeroTopMarginClass1}
            >
              <Typography
                variant="h3"
                sx={{
                  lineHeight: { xs: '20px', md: '40px' },
                  maxWidth: { xs: '268px', md: '100%' },
                  margin: 'auto',
                }}
              >
                {firstName}, {t('adaptOffer')}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  mt: { xs: '19px', md: '5px' },
                  lineHeight: { xs: '14.4px', md: '40px' },
                }}
              >
                {t('editGuaranty.title')}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              gap: { xs: '24px', md: '96px' },
              marginTop: { xs: '24px', md: '96px' },
            }}
          >
            {!isGuardType && (
              <>
                {isAlcampo() && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                      marginLeft: { xs: '0px', md: '620px' },
                      width: { xs: '100%', md: '1080px' },
                    }}
                  >
                    {formulaChoices.map((value) => (
                      <Button
                        key={value.id}
                        onClick={() =>
                          changeRateOrFormula('id', value.id)
                        }
                        sx={{
                          background:
                            activeFormula?.id === value.id
                              ? `${blue} !important`
                              : '#9ebecc',
                        }}
                        backgroundColor={
                          activeFormula?.id === value.id
                            ? blue
                            : '#9ebecc'
                        }
                      >
                        <Typography
                          variant="h3"
                          color={
                            activeFormula?.id === value.id
                              ? 'secondary'
                              : 'primary'
                          }
                          sx={{
                            fontSize: { xs: '16px', md: '32px' },
                          }}
                        >
                          {value.name}
                        </Typography>
                      </Button>
                    ))}
                  </Box>
                )}
                <Slider
                  title={t('refundRate.prevTitle')}
                  subtitle={t('refundRate.prevSubtitle')}
                >
                  {prevChoices.map((value) => (
                    <HexagonalButton
                      key={value}
                      value={`${value}€`}
                      isActive={value === prev}
                      onClick={() => {
                        changeRateOrFormula('prev', value);
                      }}
                    />
                  ))}
                </Slider>
                <Slider
                  title={t('refundRate.title')}
                  subtitle={t('refundRate.percentSubtitle')}
                >
                  {refundChoices.map((value) => (
                    <HexagonalButton
                      key={value}
                      isActive={value === refund}
                      value={`${Math.floor(value * 100)}%`}
                      onClick={() => {
                        changeRateOrFormula('refund', value);
                      }}
                    />
                  ))}
                </Slider>
                <Slider
                  title={t('refundRate.subtitle1')}
                  subtitle={t('refundRate.deductibleSubtitle')}
                >
                  {excessChoices.map((value) => (
                    <HexagonalButton
                      key={value}
                      isActive={value === excess}
                      value={`${value}€`}
                      onClick={() => {
                        changeRateOrFormula('excess', value);
                      }}
                    />
                  ))}
                </Slider>
                <Slider
                  title={t('refundRate.subtitle2')}
                  subtitle={t('refundRate.maximumRefundSubtitle')}
                >
                  {ceilChoices.map((value) => (
                    <HexagonalButton
                      key={value}
                      isActive={value === ceil}
                      value={`${value}€`}
                      onClick={() => {
                        changeRateOrFormula('ceil', value);
                      }}
                    />
                  ))}
                </Slider>
              </>
            )}
            {isGuardType && (
              <Slider
                title={t('refundRate.subtitle3')}
                subtitle={t('refundRate.annuitiesSubtitle')}
              >
                {annuityChoices.map((value) => (
                  <HexagonalButton
                    key={value}
                    isActive={value === annuity}
                    value={`${value}€`}
                    onClick={() => {
                      setAnnuity(value);
                    }}
                  />
                ))}
              </Slider>
            )}
          </Box>
          <Box
            sx={{
              mt: { xs: '20px' },
              width: { xs: '100%', md: '100%' },
            }}
          >
            <Box // component="img" alt="svg" src={quote_1_fleche}
              sx={{
                width: { xs: '25px', md: '70px' },
                height: { xs: '14px', md: '39px' },
              }}
            />
          </Box>
          <Box
            sx={{
              mt: { xs: isGuardType ? '40px' : '15px' },
              width: { xs: '100%' },
              zoom: { xs: zoomMobileConsultPrice, md: 1 },
              '@supports ( -moz-appearance:none )': {
                '-moz-transform': {
                  xs: `scale(${zoomMobileSelector})`,
                },
                marginTop: '-25px',
                marginBottom: '-30px',
              },
            }}
          >
            {resultPrice}
          </Box>
          <Box
            style={{
              marginTop: isGuardType ? 23 : 13,
              width: '100%',
            }}
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            {chooseOffer(true)}
          </Box>
          <Box
            style={{
              marginTop: isGuardType ? 23 : 13,
              width: '100%',
            }}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            {chooseOfferMobile}
          </Box>
          <Box
            style={{
              marginTop: isGuardType ? 20 : 10,
              width: '100%',
            }}
          >
            {isGuardType && (
              <Button
                style={{
                  textDecoration: 'underline',
                  color: 'rgba(0, 0, 0, 0.38)',
                  textTransform: 'none',
                }}
                onClick={openDeathInfo}
              >
                <Typography
                  variant={'h6'}
                  style={{ color: 'rgba(0, 0, 0, 0.38)' }}
                >
                  {t('refundRate.moreInfoButton')}
                </Typography>
              </Button>
            )}
          </Box>
        </Box>
      </section>
      <section
        id={'section2-quote'}
        style={{
          display:
            isGuardType || !availableGuarantees?.includes('guard')
              ? 'none'
              : 'block',
        }}
        className={classes.homeSection2}
      >
        <Box
          sx={{
            mt: { xs: '36px', md: '88px' },
            width: { xs: '100%' },
          }}
        >
          <Typography
            variant="h3"
            sx={{ lineHeight: { xs: '20px', md: '40px' } }}
          >
            <Hidden mdUp>
              {t('refundRate.sectionNotGuard.title1')}
              <br />
              {t('refundRate.sectionNotGuard.title2')}
              <span style={{ display: 'inline-block' }}>
                {t('refundRate.sectionNotGuard.title3')}
              </span>
            </Hidden>
            <Hidden mdDown>
              {t('refundRate.sectionNotGuard.mobile.title1')}
            </Hidden>
          </Typography>
          <Typography
            variant="h5"
            sx={{
              margin: 'auto',
              mt: { xs: '20px', md: '65px' },
              lineHeight: { xs: '14px', md: '30px' },
              maxWidth: { xs: '80%', md: '100%' },
            }}
          >
            <Hidden mdDown>
              {t('refundRate.sectionNotGuard.subtitle1')}
              <br />
              {t('refundRate.sectionNotGuard.subtitle2')}
            </Hidden>
            <Hidden mdUp>
              {t('refundRate.sectionNotGuard.mobile.subtitle1')}
              <span style={{ display: 'inline-block' }}>
                {t('refundRate.sectionNotGuard.mobile.subtitle2')}
              </span>
            </Hidden>
          </Typography>
        </Box>
        <Box>
          <Box
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
            sx={{
              marginTop: { xs: '18px', md: '88px' },
              height: { xs: '330px', md: '304px' },
            }}
          >
            <Box style={{ position: 'absolute' }}>
              <Hidden mdDown>
                <img
                  alt="svg"
                  src={quote_3_background}
                  style={{ width: '1445px', height: '304px' }}
                />
              </Hidden>
              <Hidden mdUp>
                <img
                  alt="svg"
                  src={mobile_2_square}
                  style={{ width: '275px', height: '330px' }}
                />
              </Hidden>
            </Box>
            <Box
              style={{ zIndex: 10 }}
              sx={{
                width: { xs: '275px', md: '1445px' },
                height: { xs: '330px', md: '304px' },
              }}
            >
              <Stack
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
                sx={{
                  flexDirection: { xs: 'column', md: 'row' },
                  paddingLeft: { xs: 0, md: '40px' },
                  paddingRight: { xs: 0, md: '40px' },
                }}
                width="100%"
                height="100%"
              >
                <Box
                  component="img"
                  alt="svg"
                  src={angel_img}
                  sx={{
                    width: { xs: '120px', md: '300px' },
                    marginTop: { xs: '10px', md: '-20px' },
                  }}
                />
                <Stack
                  direction="column"
                  height="100%"
                  justifyContent="space-evenly"
                >
                  <Stack
                    sx={{
                      flexDirection: { xs: 'column', md: 'row' },
                      marginBottom: { xs: '20px', md: 0 },
                    }}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant={'h5'}
                      sx={{ marginBottom: { xs: '10px', md: 0 } }}
                    >
                      {t(
                        'refundRate.sectionNotGuard.wantThisGuaranty',
                      )}
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        // height: '52px',
                        zoom: { xs: 0.4787878, md: 1 },
                        '-webkit-text-size-adjust': 'auto',
                        '@supports ( -moz-appearance:none )': {
                          '-moz-transform': {
                            xs: `scale(${0.4787878})`,
                            md: `scale(${1})`,
                          },
                          marginBottom: '20px',
                        },
                      }}
                    >
                      <Switch2
                        checked={option}
                        onChange={(value) => {
                          setOption(value);
                          if (value && !isAgeValid) {
                            setTimeout(() => {
                              openAgeConfirmationModal();
                            }, 500);
                          }
                        }}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    sx={{
                      flexDirection: { xs: 'column', md: 'row' },
                    }}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant={'h5'}
                      sx={{
                        marginBottom: { xs: '10px', md: 0 },
                        color: option ? '' : 'rgba(0, 0, 0, 0.38);',
                        '@supports ( -moz-appearance:none )': {
                          marginBottom: 0,
                        },
                      }}
                    >
                      {t('refundRate.sectionNotGuard.yourAge')}
                    </Typography>
                    {userAge > 0 ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{ marginLeft: { xs: 0, md: '50px' } }}
                      >
                        <Typography
                          variant={'h5'}
                          sx={{
                            color: option
                              ? ''
                              : 'rgba(0, 0, 0, 0.38);',
                            '@supports ( -moz-appearance:none )': {
                              marginBottom: 0,
                            },
                          }}
                        >
                          {userAge} {t('age')}
                        </Typography>
                        <Button
                          style={{ marginLeft: '20px', padding: 0 }}
                          sx={{ color: 'rgba(0, 0, 0, 0.38)' }}
                          disabled={!option}
                          onClick={() => {
                            openAgeConfirmationModal(true);
                          }}
                        >
                          <Typography
                            variant={'h6'}
                            style={{ textDecoration: 'underline' }}
                            sx={{
                              color: option
                                ? ''
                                : 'rgba(0, 0, 0, 0.38);',
                            }}
                          >
                            {t('modify')}
                          </Typography>
                        </Button>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Stack>
                <Box
                  sx={{
                    zoom: { xs: 0.4787878, md: 1 },
                    marginBottom: { xs: '30px', md: 0 },
                    '@supports ( -moz-appearance:none )': {
                      marginTop: '-26px',
                      '-moz-transform': {
                        xs: `scale(${0.4787878})`,
                        md: `scale(${1})`,
                      },
                    },
                  }}
                >
                  {supplementPrice()}
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
        <Box style={{ marginTop: 13, width: '100%' }}>
          <Button
            style={{
              textDecoration: 'underline',
              color: 'rgba(0, 0, 0, 0.38)',
              textTransform: 'none',
            }}
            onClick={openDeathInfo}
          >
            <Typography
              variant={'h6'}
              style={{ color: 'rgba(0, 0, 0, 0.38)' }}
            >
              {t('refundRate.moreInfoButton')}
            </Typography>
          </Button>
        </Box>

        <Box
          sx={{
            mt: { xs: '23px', md: '23px' },
            width: { xs: '100%' },
          }}
        >
          <Box
            sx={{
              width: { xs: '100%' },
              zoom: { xs: zoomMobileConsultPrice, md: 1 },
              '-moz-transform': {
                xs: `scale(${zoomMobileConsultPrice})`,
                md: `scale(${1})`,
              },
            }}
          >
            {resultPrice}
          </Box>
        </Box>
      </section>
      <section
        ref={ref2}
        id={'section4-quote'}
        style={{ display: isGuardType ? 'none' : 'block' }}
        className={classes.homeSection4}
      >
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <img
            alt="svg"
            src={quote_4_transition}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '50%',
            }}
          />
          <Box marginTop={'33px'}>{chooseOfferExtended}</Box>
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <img
            alt="svg"
            src={mobile_4_transition}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '101%',
              height: heightMobile + 15,
            }}
          />
          <Box marginTop={'7px'}>{chooseOfferExtendedMobile}</Box>
        </Box>

        <Box
          sx={{
            mt: { xs: '89px', md: '217px' },
            width: '100%',
          }}
        >
          <Typography
            variant="h3"
            sx={{
              lineHeight: { xs: '20px', md: '40px' },
            }}
          >
            {t('refundRate.sectionNotGuard.whenRefund.text1')}
            <span className={classes.displayDesktopMobile}>
              &nbsp;
            </span>
            <span className={classes.displayMobileDesktop} />
            {t('refundRate.sectionNotGuard.whenRefund.text2')}
          </Typography>
          <Typography
            variant="h5"
            marginTop={'5px'}
            lineHeight={'20px'}
          >
            {t('refundRate.sectionNotGuard.whenRefund.text3')}
          </Typography>
        </Box>

        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Box
            style={{
              marginTop: 134,
              width: '100%',
              position: 'relative',
            }}
            className={classes.imgCenter}
          >
            <img
              alt="svg"
              src={quote_4_img}
              style={{
                position: 'absolute',
                top: '10px',
                left: '870px',
                width: '950px',
              }}
            />
            <Lottie
              animationData={chien_grue}
              play={isVisible2}
              loop={true}
              style={{
                position: 'absolute',
                width: '780px',
                top: '380px',
                left: 0,
              }}
            />
            <Box id={'quote-price-board'}>
              <Typography
                color={'white'}
                style={{
                  position: 'absolute',
                  top: '67px',
                  left: '1055.004px',
                  fontSize: '59.01px',
                  textAlign: 'left',
                }}
              >
                {`1000€ x ${100 * refund}% - ${excess}€`}
              </Typography>
              <Typography
                color={'white'}
                style={{
                  position: 'absolute',
                  top: '146px',
                  left: '1061.660px',
                  fontSize: '19.67px',
                  textAlign: 'left',
                }}
              >
                {t('refundRate.sectionNotGuard.whenRefund.text4')}
              </Typography>
              <Typography
                color={'white'}
                style={{
                  position: 'absolute',
                  top: '207px',
                  left: '1270px',
                  fontSize: '65.31px',
                  textAlign: 'left',
                }}
              >
                {`= ${1000 * refund - excess}€`}
              </Typography>
            </Box>

            <Box
              style={{
                position: 'absolute',
                top: 0,
                left: '91.1871px',
              }}
            >
              <img
                alt="svg"
                src={quote_4_rect}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '444.7331px',
                  height: '91.2475px',
                }}
              />
              <Box
                style={{
                  position: 'absolute',
                  width: '640px',
                  left: 20,
                  top: 15,
                }}
              >
                <Typography
                  variant="h5"
                  marginTop={'5px'}
                  lineHeight={'42px'}
                  textAlign={'left'}
                  fontSize={'37px'}
                >
                  {t(
                    'refundRate.sectionNotGuard.whenRefund.example.text1',
                  )}{' '}
                  <br />
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  lineHeight={'40px'}
                  textAlign={'left'}
                >
                  {t(
                    'refundRate.sectionNotGuard.whenRefund.example.text2',
                    { animalName },
                  )}{' '}
                  <br />
                  {t(
                    'refundRate.sectionNotGuard.whenRefund.example.text3',
                  )}{' '}
                  <br />
                  {t(
                    'refundRate.sectionNotGuard.whenRefund.example.text4',
                  )}
                </Typography>
              </Box>
            </Box>

            <Box
              style={{
                position: 'absolute',
                width: '640px',
                left: 760,
                top: 810,
              }}
            >
              <Typography
                variant="h5"
                lineHeight={'40px'}
                textAlign={'right'}
              >
                {t(
                  'refundRate.sectionNotGuard.whenRefund.example.text5',
                  { animalName },
                )}{' '}
                <br />
                {t(
                  'refundRate.sectionNotGuard.whenRefund.example.text6',
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Box
            style={{
              marginTop: '40px',
              width: '100%',
              position: 'relative',
            }}
          >
            <Box
              position={'absolute'}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <div style={{ width: '170px' }}>
                <img src={mobile_3_text_1} alt="transition" />
              </div>
            </Box>
            <Box
              position={'relative'}
              width={'100%'}
              paddingTop={'4px'}
            >
              <Typography
                variant="h4"
                sx={{ lineHeight: { xs: '20px', md: '40px' } }}
              >
                {t(
                  'refundRate.sectionNotGuard.whenRefund.example.text1',
                )}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  mt: { xs: '19px', md: '5px' },
                  lineHeight: { xs: '14.4px', md: '40px' },
                }}
              >
                {t(
                  'refundRate.sectionNotGuard.whenRefund.example.text2',
                  { animalName },
                )}
                <br />
                {t(
                  'refundRate.sectionNotGuard.whenRefund.example.text3',
                )}
              </Typography>
            </Box>
          </Box>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <Lottie
              animationData={chien_grue}
              play={isVisible2}
              loop={true}
              style={{ width: '300px' }}
            />
          </div>
          <Typography
            variant="h5"
            sx={{
              mt: '20px',
              lineHeight: '14.4px',
            }}
          >
            {t('refundRate.sectionNotGuard.whenRefund.example.text4')}
          </Typography>
          <Box
            sx={{
              width: '299.6286px',
              height: '154.3201px',
              left: '21.3714px',
              mt: '37px',
              position: 'relative',
            }}
            className={classes.imgCenter}
          >
            <Box
              component="img"
              alt="svg"
              src={mobile_4_dog}
              sx={{
                width: '299.6286px',
                height: '154.3201px',
              }}
            ></Box>
            <Box
              id={'quote-price-board'}
              style={{
                position: 'absolute',
                width: '100%',
                top: '0',
                left: '0',
              }}
            >
              <Typography
                color={'white'}
                style={{
                  position: 'absolute',
                  top: '20px',
                  left: '60px',
                  width: '100%',
                  fontSize: '17.79px',
                  textAlign: 'left',
                }}
              >
                {`1000€ x ${100 * refund}% - ${excess}€`}
              </Typography>
              <Typography
                color={'white'}
                style={{
                  position: 'absolute',
                  top: '45px',
                  left: '63px',
                  fontSize: '5.93px',
                  textAlign: 'left',
                }}
              >
                {t('refundRate.sectionNotGuard.whenRefund.text4')}
              </Typography>
              <Typography
                color={'white'}
                style={{
                  position: 'absolute',
                  top: '63px',
                  left: '125px',
                  fontSize: '19.69px',
                  textAlign: 'left',
                }}
              >
                {`= ${1000 * refund - excess}€`}
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              marginTop: '40px',
              width: '100%',
              position: 'relative',
            }}
          >
            <Box
              position={'absolute'}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <div style={{ width: '140px' }}>
                <img src={mobile_3_text_2} alt="transition" />
              </div>
            </Box>

            <Box
              position={'relative'}
              width={'100%'}
              paddingTop={'4px'}
            >
              <Typography
                variant="h4"
                sx={{
                  lineHeight: { xs: '20px', md: '40px' },
                }}
              >
                {t(
                  'refundRate.sectionNotGuard.mobile.whenRefund.example.text1',
                )}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  mt: { xs: '19px', md: '5px' },
                  lineHeight: { xs: '14.4px', md: '40px' },
                }}
              >
                {t(
                  'refundRate.sectionNotGuard.whenRefund.example.text5',
                  { animalName },
                )}{' '}
                <br />
                {t(
                  'refundRate.sectionNotGuard.whenRefund.example.text6',
                )}
              </Typography>
            </Box>
          </Box>
          <Box
            component="img"
            alt="svg"
            src={mobile_4_dog_1}
            sx={{
              width: '154.2941px',
              height: '217px',
              mt: '37px',
            }}
          ></Box>
        </Box>
      </section>
      <section
        id={'section5-quote'}
        style={{ display: isGuardType ? 'none' : 'block' }}
        className={classes.homeSection5}
      >
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <img
            alt="svg"
            src={quote_5_transition}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '33%',
            }}
          />
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <img
            alt="svg"
            src={mobile_5_transition}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '600px',
            }}
          />
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <div style={{ position: 'relative', width: '100%' }}>
            <Typography
              variant={'h3'}
              style={{
                marginTop: '120px',
                lineHeight: '20px',
              }}
            >
              {t(
                'refundRate.sectionNotGuard.whenRefund.weCare.text1',
              )}{' '}
              <br />
              {t(
                'refundRate.sectionNotGuard.whenRefund.weCare.text2',
              )}
            </Typography>
            <Typography
              variant={'h3'}
              style={{
                lineHeight: '18px',
              }}
            >
              <br />
            </Typography>

            <Typography
              variant={'h5'}
              style={{
                lineHeight: '14px',
              }}
            >
              {t(
                'refundRate.sectionNotGuard.whenRefund.weCare.text3',
              )}{' '}
              <br />
              {t(
                'refundRate.sectionNotGuard.whenRefund.weCare.text4',
              )}
            </Typography>
          </div>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Typography
            variant={'h5'}
            style={{
              fontSize: '37px',
              lineHeight: '60px',
              marginTop: 300,
            }}
          >
            {t(
              'refundRate.sectionNotGuard.mobile.whenRefund.weCare.text1',
            )}
          </Typography>
          <Typography variant={'h5'} style={{ lineHeight: '40px' }}>
            {t(
              'refundRate.sectionNotGuard.mobile.whenRefund.weCare.text2',
            )}
          </Typography>
        </Box>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={{ xs: '72px', md: ' 220px' }}
          sx={{
            mt: { xs: '67px', md: '87px' },
          }}
        >
          <Box
            sx={{
              width: { xs: '105px', md: '450px' },
            }}
          >
            <Box
              sx={{
                width: { xs: 77.4823, md: 223.4555 },
                height: { xs: 132.4937, md: 382.1059 },
                margin: { xs: 'auto' },
              }}
            >
              <img alt="svg" src={home_elements_18} />
            </Box>
            <Box
              sx={{
                position: { xs: 'relative' },
                mt: { xs: '26px', md: '30px' },
              }}
            >
              <Box
                sx={{
                  position: { xs: 'absolute' },
                  width: { xs: '104.2451px', md: '268px' },
                  height: { xs: '25.6723px', md: '66px' },
                  left: { xs: '0px', md: '90px' },
                }}
              >
                <img
                  alt="svg"
                  src={home_elements_24}
                  style={{ width: '100%' }}
                />
              </Box>
              <Box style={{ position: 'absolute', width: '100%' }}>
                <Typography
                  variant={'h4'}
                  sx={{
                    mt: { xs: '5px', md: '20.847px' },
                    fontSize: { xs: '13px', md: '33px' },
                    lineHeight: { xs: '14.4px', md: '24px' },
                  }}
                >
                  {t('sectionAccidentIllness.accidents.title')}
                </Typography>
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                  <Typography
                    variant={'h6'}
                    style={{ lineHeight: '24px' }}
                  >
                    <br />
                    {isAlcampo()
                      ? t(
                          'sectionAccidentIllness.accidents.alcampo.subtitle1',
                        )
                      : t(
                          'sectionAccidentIllness.accidents.subtitle1',
                        )}
                    <br />
                    {t('sectionAccidentIllness.accidents.subtitle2')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: '105px', md: '450px' },
            }}
          >
            <Box
              sx={{
                width: { xs: 93.098, md: 269.2222 },
                height: { xs: 132.1337, md: 382.1059 },
                margin: { xs: 'auto' },
              }}
            >
              <img alt="svg" src={home_elements_19} />
            </Box>
            <Box
              sx={{
                position: { xs: 'relative' },
                mt: { xs: '26px', md: '30px' },
              }}
            >
              <Box
                sx={{
                  position: { xs: 'absolute' },
                  width: { xs: '104.2451px', md: '263px' },
                  height: { xs: '25.6723px', md: '66px' },
                  left: { xs: '0px', md: '95px' },
                }}
              >
                <img
                  alt="svg"
                  src={home_elements_25}
                  style={{ width: '100%' }}
                />
              </Box>
              <Box style={{ position: 'absolute', width: '100%' }}>
                <Typography
                  variant={'h3'}
                  sx={{
                    mt: { xs: '5px', md: '20.847px' },
                    fontSize: { xs: '13px', md: '33px' },
                    lineHeight: { xs: '14.4px', md: '24px' },
                  }}
                >
                  {t('sectionAccidentIllness.illnesses.title')}
                </Typography>
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                  <Typography
                    variant={'h6'}
                    style={{ lineHeight: '24px' }}
                  >
                    <br />
                    {t('sectionAccidentIllness.illnesses.subtitle1')}
                    <br />
                    {t('sectionAccidentIllness.illnesses.subtitle2')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Stack>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <div style={{ position: 'relative', width: '100%' }}>
            <Typography
              variant={'h3'}
              style={{
                marginTop: '88px',
                lineHeight: '20px',
              }}
            >
              {t('whatWeRefund.title1')}
              <br />
              {t('whatWeRefund.title2')}
            </Typography>
            <Typography
              variant={'h3'}
              style={{
                lineHeight: '18px',
              }}
            >
              <br />
            </Typography>

            <Typography
              variant={'h5'}
              style={{
                lineHeight: '14px',
              }}
            >
              {t('whatWeRefund.subtitle1')}
            </Typography>

            <div
              style={{
                width: 25,
                margin: 'auto',
                height: 15,
                marginTop: '48px',
              }}
            >
              {/*<img src={home_elements_10_mobile} alt="transition" />*/}
            </div>
          </div>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Typography
            variant={'h5'}
            style={{
              marginTop: '270px',
              fontSize: '37px',
              lineHeight: '60px',
            }}
          >
            {t('whatWeRefund.mobile.title1')}
          </Typography>
          <Typography variant={'h5'} style={{ lineHeight: '40px' }}>
            {t('whatWeRefund.mobile.subtitle1')}
          </Typography>
        </Box>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={{ xs: '47px', md: ' 220px' }}
          sx={{
            mt: { xs: '44px', md: '85px' },
          }}
        >
          <Box
            sx={{
              width: { xs: '140px', md: '450px' },
            }}
          >
            <Box
              sx={{
                width: { xs: 103.3514, md: 302.8542 },
                height: { xs: 112.9564, md: 331 },
                margin: { xs: 'auto' },
              }}
            >
              <img alt="svg" src={home_elements_21} />
            </Box>
            <Box
              sx={{
                position: { xs: 'relative' },
                mt: { xs: '26px', md: '30px' },
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{ position: 'absolute', width: '100%' }}
              >
                <Typography
                  variant={'h4'}
                  sx={{
                    mt: { xs: '4px', md: '20.847px' },
                    fontSize: { xs: '13px', md: '33px' },
                    lineHeight: { xs: '14.4px', md: '24px' },
                    padding: { xs: '8px', md: '20px' },
                  }}
                  style={{
                    backgroundImage: `url(${home_elements_26})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }}
                >
                  {t('whatWeRefund.consultation.title')}
                </Typography>
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                  <Typography
                    variant={'h6'}
                    style={{ lineHeight: '24px' }}
                  >
                    <br />
                    {t('whatWeRefund.consultation.subtitle1')}
                    <br />
                    {t('whatWeRefund.consultation.subtitle2')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: '140px', md: '450px' },
            }}
          >
            <Box
              sx={{
                width: { xs: 139.4646, md: 476.0285 },
                height: { xs: 85.6087, md: 292.2044 },
                margin: { xs: 'auto' },
                mt: { xs: '20px', md: '20px' },
              }}
            >
              <img alt="svg" src={home_elements_20} />
            </Box>
            <Box
              sx={{
                position: { xs: 'relative' },
                mt: { xs: '33px', md: '50px' },
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{ position: 'absolute', width: '100%' }}
              >
                <Typography
                  variant={'h4'}
                  sx={{
                    mt: { xs: '4px', md: '20.847px' },
                    fontSize: { xs: '13px', md: '33px' },
                    lineHeight: { xs: '14.4px', md: '24px' },
                    padding: { xs: '8px', md: '20px' },
                  }}
                  style={{
                    backgroundImage: `url(${home_elements_27})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }}
                >
                  {t('whatWeRefund.hospitalization.title')}
                </Typography>
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                  <Typography
                    variant={'h6'}
                    style={{ lineHeight: '24px' }}
                  >
                    <br />
                    {t('whatWeRefund.hospitalization.subtitle1')}
                    <br />
                    {t('whatWeRefund.hospitalization.subtitle2')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={{ xs: '73px', md: '145px' }}
          sx={{
            mt: { xs: '89px', md: '240px' },
          }}
        >
          <Box
            sx={{
              width: { xs: '108px', md: '551px' },
            }}
          >
            <Box
              sx={{
                width: { xs: 104.7064, md: 350.5719 },
                height: { xs: 84.2258, md: 282 },
                margin: { xs: 'auto' },
                mt: { xs: '14px', md: 'auto' },
              }}
            >
              <img alt="svg" src={home_elements_22} />
            </Box>
            <Box
              sx={{
                position: { xs: 'relative' },
                mt: { xs: '30px', md: '50px' },
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{ position: 'absolute', width: '100%' }}
              >
                <Typography
                  variant={'h4'}
                  sx={{
                    mt: { xs: '4px', md: '20.847px' },
                    fontSize: { xs: '13px', md: '33px' },
                    lineHeight: { xs: '14.4px', md: '24px' },
                    padding: { xs: '8px', md: '20px' },
                  }}
                  style={{
                    backgroundImage: `url(${home_elements_28})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }}
                >
                  {t('whatWeRefund.exams.title')}
                </Typography>
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                  <Typography
                    variant={'h6'}
                    style={{ lineHeight: '24px' }}
                  >
                    <br />
                    {t('whatWeRefund.exams.subtitle1')}
                    <br />
                    {t('whatWeRefund.exams.subtitle2')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: '108px', md: '551px' },
            }}
          >
            <Box
              sx={{
                width: { xs: 94.6987, md: 271.905 },
                height: { xs: 104.3217, md: 299.5353 },
                margin: { xs: 'auto' },
              }}
            >
              <img alt="svg" src={home_elements_23} />
            </Box>
            <Box
              sx={{
                position: { xs: 'relative' },
                mt: { xs: '16px', md: '15px' },
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{ position: 'absolute', width: '100%' }}
              >
                <Typography
                  variant={'h4'}
                  sx={{
                    mt: { xs: '4px', md: '20.847px' },
                    fontSize: { xs: '13px', md: '33px' },
                    lineHeight: { xs: '14.4px', md: '24px' },
                    padding: { xs: '8px', md: '25px' },
                  }}
                  style={{
                    backgroundImage: `url(${home_elements_29})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }}
                >
                  {t('whatWeRefund.medicine.title')}
                </Typography>
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                  <Typography
                    variant={'h6'}
                    style={{ lineHeight: '24px' }}
                  >
                    <br />
                    {t('whatWeRefund.medicine.subtitle1')} <br />
                    {t('whatWeRefund.medicine.subtitle2')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Stack>
      </section>
      <section
        id={'section6-quote'}
        style={{ display: isGuardType ? 'none' : 'block' }}
        className={classes.homeSection6}
        ref={ref3}
      >
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <img
            alt="svg"
            src={mobile_6_transition}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <img
            alt="svg"
            src={quote_6_transition}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
        <Box
          sx={{ display: { xs: 'none', md: 'block' } }}
          style={{
            position: 'absolute',
            width: '100%',
          }}
        >
          <Typography
            variant={'h5'}
            style={{
              fontSize: '37px',
              lineHeight: '60px',
              marginTop: '162px',
            }}
          >
            {t('whatWeRefund.notaBene.text1')}
          </Typography>
          <Typography variant={'h5'} style={{ lineHeight: '40px' }}>
            {t('whatWeRefund.notaBene.text2')}
          </Typography>
        </Box>

        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Box
            style={{
              marginTop: '77px',
              width: '100%',
              position: 'relative',
            }}
          >
            <Box
              position={'absolute'}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <div style={{ width: '210px' }}>
                <img src={mobile_6_title1} alt="transition" />
              </div>
            </Box>

            <Box
              position={'relative'}
              width={'100%'}
              paddingTop={'4px'}
            >
              <Typography
                variant="h4"
                sx={{
                  lineHeight: { xs: '20px', md: '40px' },
                }}
              >
                {t('whatWeRefund.mobile.notaBene.text1')}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  mt: { xs: '19px', md: '5px' },
                  lineHeight: { xs: '14.4px', md: '40px' },
                }}
              >
                {t('whatWeRefund.mobile.notaBene.text2')} <br />
                {t('whatWeRefund.mobile.notaBene.text3')}
              </Typography>
            </Box>
          </Box>

          <Box
            position={'relative'}
            sx={{
              mt: '30px',
              width: '100%',
            }}
          >
            <Box // component="img" alt="svg" src={quote_1_fleche}
              sx={{
                width: { xs: '25px', md: '70px' },
                height: { xs: '14px', md: '39px' },
              }}
            />
          </Box>
          <Box
            position={'relative'}
            sx={{
              mt: '20px',
              width: '100%',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                lineHeight: { xs: '14.4px', md: '40px' },
              }}
            >
              {t('whatWeRefund.mobile.notRefund.text1')} <br />
              {t('whatWeRefund.mobile.notRefund.text2')} <br />
              {t('whatWeRefund.mobile.notRefund.text3')} <br />
              {t('whatWeRefund.mobile.notRefund.text4')} <br />
              <br />
              {t('whatWeRefund.mobile.notRefund.text5', {})} <br />
              {t('whatWeRefund.mobile.notRefund.text6', {
                animalName,
              })}
            </Typography>
          </Box>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <Lottie
              animationData={coccinelle}
              play={isVisible3}
              loop={true}
              style={{ width: '220px', marginLeft: '50px' }}
            />
          </div>

          <Box
            style={{
              marginTop: '77px',
              width: '100%',
              position: 'relative',
            }}
          >
            <Box
              position={'absolute'}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <div style={{ width: '175px' }}>
                <img src={mobile_6_title2} alt="transition" />
              </div>
            </Box>

            <Box
              position={'relative'}
              width={'100%'}
              paddingTop={'4px'}
            >
              <Typography
                variant="h4"
                color={'white'}
                sx={{
                  lineHeight: { xs: '20px', md: '40px' },
                }}
              >
                {t('whatWeRefund.mobile.notRefund.example.text1')}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  mt: { xs: '19px', md: '5px' },
                  lineHeight: { xs: '14px', md: '40px' },
                }}
              >
                {t('whatWeRefund.mobile.notRefund.example.text2', {
                  animalName,
                })}{' '}
                <br />
                {t(
                  'whatWeRefund.mobile.notRefund.example.text3',
                )}{' '}
                <br />
                {t(
                  'whatWeRefund.mobile.notRefund.example.text4',
                )}{' '}
                <br />
                <br />
                {t(
                  'whatWeRefund.mobile.notRefund.example.text5',
                )}{' '}
                <br />
                {t('whatWeRefund.mobile.notRefund.example.text6')}
                <br />
                {t('whatWeRefund.mobile.notRefund.example.text7')}
              </Typography>
            </Box>
          </Box>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '50px',
            }}
          >
            <Lottie
              animationData={landeau}
              play={isVisible3}
              loop={true}
              style={{
                width: '350px',
              }}
            />
          </div>
        </Box>
        <Box
          style={{
            position: 'relative',
            width: '100%',
          }}
          sx={{ display: { xs: 'none', md: 'block' } }}
          className={classes.imgCenter}
        >
          <Box
            style={{
              position: 'absolute',
              width: '100%',
              top: '380.9629px',
            }}
          >
            <Lottie
              animationData={coccinelle}
              play={isVisible3}
              loop={true}
              style={{
                position: 'absolute',
                width: '596px',
                top: '-70px',
                left: '1200px',
              }}
            />
            <Lottie
              animationData={landeau}
              play={isVisible3}
              loop={true}
              style={{
                position: 'absolute',
                width: '930px',
                top: '500px',
                left: 0,
              }}
            />
            <Box
              style={{
                position: 'absolute',
                maxWidth: '780px',
                left: '188.4457px',
              }}
            >
              <Typography
                variant={'h5'}
                lineHeight={'40px'}
                textAlign={'left'}
                marginTop={'70px'}
              >
                {t('whatWeRefund.notRefund.text1')} <br />
                {t('whatWeRefund.notRefund.text2')}
                <br />
                {t('whatWeRefund.notRefund.text3')} <br />
                <br />
                {t('whatWeRefund.notRefund.text4')} <br />
                {t('whatWeRefund.notRefund.text5', { animalName })}
                <br />
                {t('whatWeRefund.notRefund.text6')}
              </Typography>
            </Box>
            <Box
              style={{
                position: 'absolute',
                top: '842px',
                left: '946px',
              }}
            >
              <img
                alt="svg"
                src={quote_6_rect}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '424.7331px',
                  height: '81.2475px',
                }}
              />
              <Box
                style={{
                  position: 'absolute',
                  width: '840px',
                  left: 20,
                  top: 15,
                }}
              >
                <Typography
                  variant="h5"
                  marginTop={'5px'}
                  lineHeight={'42px'}
                  textAlign={'left'}
                  fontSize={'37px'}
                  color={'white'}
                >
                  {t('whatWeRefund.notRefund.example.text1')} <br />
                  <br />
                </Typography>
                <Typography
                  variant="h5"
                  lineHeight={'40px'}
                  textAlign={'left'}
                >
                  {t('whatWeRefund.notRefund.example.text2', {
                    animalName,
                  })}
                  <br />
                  {t('whatWeRefund.notRefund.example.text3')}
                  <br />
                  {t('whatWeRefund.notRefund.example.text4')} <br />
                  <br />
                  {t('whatWeRefund.notRefund.example.text5')} <br />
                  {t('whatWeRefund.notRefund.example.text6')}
                  <br />
                  {t('whatWeRefund.notRefund.example.text7')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </section>
      <section
        id={'section7-quote'}
        style={{ display: isGuardType ? 'none' : 'block' }}
        className={classes.homeSection7}
      >
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Box style={{ position: 'relative', width: '100%' }}>
            <Typography
              variant={'h3'}
              marginTop={'43px'}
              style={{ lineHeight: '20px' }}
            >
              {t('whatWeRefund.mobile.futurRefund.text1')} <br />
              {t('whatWeRefund.mobile.futurRefund.text2')} <br />
              {t('whatWeRefund.mobile.futurRefund.text3')}
            </Typography>
            <Typography
              variant={'h5'}
              marginTop={'23px'}
              style={{ lineHeight: '14px' }}
            >
              {t('whatWeRefund.mobile.futurRefund.text4')}
              <FavoriteIcon
                sx={{
                  fontSize: { xs: '13px', md: 'inherit' },
                  transform: {
                    xs: 'translateY(2px)',
                    md: 'translateY(5px)',
                  },
                }}
                style={{ color: '#d32d00' }}
              />
            </Typography>
            <Box
              position={'absolute'}
              sx={{
                left: '-78px',
                top: '153px',
                width: '519px',
                height: '449.1296px',
              }}
            >
              <div className={classes.imgCenter}>
                <img src={mobile_7_img1} alt="transition" />
              </div>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ display: { xs: 'none', md: 'block' } }}
          style={{ position: 'absolute', width: '100%' }}
        >
          <Typography
            variant={'h5'}
            style={{
              fontSize: '37px',
              lineHeight: '60px',
              marginTop: '81px',
            }}
          >
            {t('whatWeRefund.futurRefund.text1')} <br />
            {t('whatWeRefund.futurRefund.text2')}
          </Typography>
          <Typography variant={'h5'} style={{ lineHeight: '42px' }}>
            <br />
            {t('whatWeRefund.futurRefund.text3')}
            <FavoriteIcon
              sx={{
                fontSize: { xs: '13px', md: 'inherit' },
                transform: {
                  xs: 'translateY(2px)',
                  md: 'translateY(5px)',
                },
              }}
              style={{ color: '#d32d00' }}
            />
          </Typography>
        </Box>
        <Box
          sx={{ display: { xs: 'none', md: 'block' } }}
          className={classes.imgCenter}
        >
          <img
            alt="svg"
            src={quote_7_img}
            style={{
              position: 'absolute',
              left: '-138.511px',
              top: '147px',
              width: '2194px',
              height: '841.86px',
            }}
          />
        </Box>
      </section>

      <section id={'section8-quote'} className={classes.homeSection8}>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <img
            alt="svg"
            src={mobile_7_transition}
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '600px',
            }}
          />
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Box style={{ position: 'relative', width: '100%' }}>
            <Typography
              variant={'h3'}
              style={{ marginTop: '47px', lineHeight: '20px' }}
            >
              {t('whatWeRefund.mobile.questions.title')} <br />
            </Typography>
            <Typography
              variant={'h5'}
              marginTop={'23px'}
              style={{ lineHeight: '14px' }}
            >
              {isAlcampo()
                ? t('whatWeRefund.mobile.questions.alcampo.subtitle1')
                : t('whatWeRefund.mobile.questions.subtitle1')}
              <br />
              {t('whatWeRefund.mobile.questions.subtitle2')}{' '}
              {isAlcampo() && process.env.REACT_APP_PHONE_NUMBER} !
              <br />
              {t('whatWeRefund.mobile.questions.subtitle3')}
            </Typography>
            <Box sx={{ mt: '68px', width: '100%' }}>
              <Box
                component="img"
                alt="svg"
                src={mobile_7_img2}
                sx={{ width: '296.4172px' }}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <img
            alt="svg"
            src={quote_7_transition}
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '1760px',
            }}
          />
        </Box>
        <Box
          sx={{ display: { xs: 'none', md: 'block' } }}
          className={classes.imgCenter}
        >
          <img
            alt="svg"
            src={quote_7_img2}
            style={{
              position: 'absolute',
              left: '134.8135px',
              top: '158px',
              width: '1740px',
              height: '474.6126px',
            }}
          />
          <Box
            style={{
              position: 'absolute',
              left: '147.3672px',
              top: '53px',
              width: '100%',
            }}
          >
            <Typography
              variant={'h5'}
              textAlign={'left'}
              style={{ fontSize: '37px', lineHeight: '60px' }}
            >
              {t('whatWeRefund.questions.title')}
            </Typography>
            <Typography
              variant={'h5'}
              textAlign={'left'}
              style={{ lineHeight: '42px' }}
            >
              <br />
              {isAlcampo()
                ? t('whatWeRefund.questions.alcampo.subtitle1')
                : t('whatWeRefund.questions.subtitle1')}{' '}
              <br />
              {t('whatWeRefund.questions.subtitle2')}
              {isAlcampo() &&
                process.env.REACT_APP_PHONE_NUMBER} ! <br />
              {t('whatWeRefund.questions.subtitle3')}
            </Typography>
          </Box>
        </Box>
      </section>
      <Footer />
    </Box>
  );
}

export default QuotePage;
