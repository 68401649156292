import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  CssBaseline,
  Hidden,
  Link,
  Toolbar,
  Tooltip,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import avatar_julie from '../../../static/julie.png';
import avatar_mila from '../../../static/mila.png';
import { blue, yellow } from '../../../theme';
import { isAlcampo } from '../../../utils/language';
import CalendlyBooking from '../../molecules/CalendlyBooking';
import Drawer from '../home/Drawer';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  children: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    position: 'absolute',
    top: 30,
    [theme.breakpoints.up('sm')]: {
      top: 0,
      background: 'transparent',
    },
    background: theme.palette.secondary.main,
  },
  appBar: {
    background: 'white',
    transition: 'height 0.2s ease 0s',
    height: 64,
    [theme.breakpoints.down('sm')]: {
      height: 46,
    },
  },
  textLogo: {
    position: 'absolute',
    left: '50%',
    top: 64,
    transition: 'transform 0.2s ease 0s',
    width: theme.spacing(10),
    height: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      top: 46,
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  },
  toolbar: {
    minHeight: 64,
    [theme.breakpoints.down('sm')]: {
      minHeight: 46,
    },
  },
  textLogoTrigger: {
    transform: ' translateX(-50%) scale(0.5) translateY(-144px)',
    [theme.breakpoints.down('md')]: {
      transform: ' translateX(-50%) scale(1) translateY(-54px)',
    },
  },
  textLogoNotTrigger: {
    left: '50%',
    transform: 'translateX(-50%) scale(1) translateY(-50%)',
  },
}));

function ElevationScroll(props) {
  const { children } = props;

  return React.cloneElement(children, {
    elevation: 4,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default function ElevateAppBar(props) {
  const { t } = useTranslation(null, { keyPrefix: 'pricing.navbar' });

  const classes = useStyles();
  const { children } = props;
  const [openCalendly, setOpenCalendly] = useState(false);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props} trigger={trigger}>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Hidden smUp>
              <Drawer />
            </Hidden>
            <Hidden smDown>
              <Typography
                sx={{ fontSize: { sm: '16px', md: '18px' } }}
              >
                <Link
                  component={RouterLink}
                  to="/"
                  color={'primary'}
                  style={{
                    textDecoration: 'none',
                    fontWeight: 'bold',
                  }}
                >
                  {isAlcampo() ? 'Alcampo Mascotas' : 'Drooky'}
                </Link>
              </Typography>
            </Hidden>
            <div className={classes.grow} />
            <Avatar
              className={clsx([
                classes.textLogo,
                trigger
                  ? classes.textLogoTrigger
                  : classes.textLogoNotTrigger,
              ])}
              alt={isAlcampo() ? 'Carmen' : 'Julie'}
              src={isAlcampo() ? avatar_mila : avatar_julie}
            />
            <Tooltip
              enterTouchDelay={0}
              title={t('mailUs')}
              followCursor
            >
              <a
                style={{
                  color: blue,
                  textDecoration: 'none',
                  paddingTop: '5px',
                  paddingRight: '8px',
                  paddingLeft: '8px',
                }}
                href={`mailto:${
                  isAlcampo()
                    ? process.env.REACT_APP_ALCAMPO_EMAIL
                    : process.env.REACT_APP_DROOKY_EMAIL
                }`}
              >
                <MailOutlineIcon fontSize="large" />
              </a>
            </Tooltip>
            <Box display={'flex'}>
              <Hidden mdDown>
                {isAlcampo() && (
                  <Button
                    sx={{
                      fontSize: {
                        sm: '14px',
                        md: '16px',
                      },
                      marginRight: '10px',
                      whiteSpace: 'nowrap',
                    }}
                    style={{
                      fontWeight: 'bold',
                      borderColor: blue,
                      border: '2px solid',
                    }}
                    color="primary"
                  >
                    <a
                      style={{
                        color: blue,
                        textDecoration: 'none',
                        display: 'flex',
                      }}
                      href={`tel:${process.env.REACT_APP_PHONE_NUMBER}`}
                    >
                      {t('callUs')}
                      <Typography
                        sx={{
                          display: { xl: 'flex', md: 'none' },
                          paddingLeft: '5px',
                          paddingTop: '2px',
                        }}
                      >
                        {process.env.REACT_APP_PHONE_NUMBER}{' '}
                      </Typography>
                    </a>
                  </Button>
                )}
                <Button
                  sx={{
                    fontSize: { sm: '14px', md: '16px' },
                    whiteSpace: 'nowrap',
                    marginRight: '10px',
                  }}
                  style={{
                    fontWeight: 'bold',
                    color: yellow,
                    background: blue,
                  }}
                  onClick={() => setOpenCalendly(!openCalendly)}
                >
                  <Typography style={{ fontWeight: 'bold' }}>
                    {t('bookAppointment')}
                  </Typography>
                </Button>
              </Hidden>
              <CalendlyBooking
                open={openCalendly}
                setOpen={setOpenCalendly}
              />
            </Box>

            {/* <LanguageSelector /> */}
          </Toolbar>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: { xs: 6, sm: 26 },
              marginTop: 2,
            }}
          >
            <Hidden mdUp>
              {isAlcampo() && (
                <Button
                  sx={{
                    fontSize: { sm: '16px', md: '18px' },
                    marginRight: '10px',
                  }}
                  style={{
                    fontWeight: 'bold',
                    borderColor: blue,
                    border: '2px solid',
                  }}
                  color="primary"
                >
                  <a
                    style={{
                      color: blue,
                      textDecoration: 'none',
                    }}
                    href={`tel:${process.env.REACT_APP_PHONE_NUMBER}`}
                  >
                    {t('callUs')}
                  </a>
                </Button>
              )}
              <Button
                style={{
                  fontWeight: 'bold',
                  color: yellow,
                  background: blue,
                }}
                onClick={() => setOpenCalendly(!openCalendly)}
              >
                <Typography
                  sx={{
                    fontSize: { sm: '16px', md: '18px' },
                  }}
                  style={{ fontWeight: 'bold' }}
                >
                  {t('bookAppointment')}
                </Typography>
              </Button>
            </Hidden>
          </Box>
        </AppBar>
      </ElevationScroll>

      <Toolbar />
      <div className={classes.children}>{children}</div>
    </React.Fragment>
  );
}
