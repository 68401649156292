import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Grid,
  Hidden,
  Link,
  Toolbar,
  Tooltip,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import alcampoLogo from '../../../static/alcampo/logo.svg';
import instagram_logo from '../../../static/home/instagram_logo.svg';
import tiktok_logo from '../../../static/home/tiktok_logo.svg';
import { blue, yellow } from '../../../theme';
import { isAlcampo } from '../../../utils/language';
import CalendlyBooking from '../../molecules/CalendlyBooking';
import Drawer from './Drawer';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  children: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    position: 'absolute',
    top: 0,
  },
  appBar: {
    background: (props) =>
      props.dogCat ? 'white' : theme.palette.secondary.main,
    color: theme.palette.primary.main,
    transition: 'height 0.2s ease 0s',
  },
  appBarTrigger: {
    height: 64,
    [theme.breakpoints.down('sm')]: {
      height: 46,
    },
  },
  appBarNotTrigger: {
    height: 100,
    [theme.breakpoints.down('sm')]: {
      height: 46,
    },
  },
  textLogo: {
    transition: 'transform 0.2s ease 0s',
  },
  toolbar: {
    minHeight: 64,
    [theme.breakpoints.down('sm')]: {
      minHeight: 46,
    },
  },
  logoContainer: {
    cursor: 'pointer',
    marginLeft: '11px',
    marginRight: '11px',
    marginTop: '6px',
    [theme.breakpoints.down('xl')]: {
      marginLeft: isAlcampo() ? '4px' : '4px',
      marginRight: isAlcampo() ? '4px' : '4px',
      marginTop: '2px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: isAlcampo() ? '10px' : '2px',
      marginRight: isAlcampo() ? '10px' : '2px',
      marginTop: '2px',
    },
    [theme.breakpoints.down('ss')]: {
      marginLeft: isAlcampo() ? '6px' : '2px',
      marginRight: isAlcampo() ? '6px' : '2px',
      marginTop: '5px',
    },
  },
  alcampoLogo: {
    width: '100%',
    maxWidth: '300px',
  },
  logo: {
    width: 30,
    height: 30,
    [theme.breakpoints.down('sm')]: {
      width: 25,
      height: 25,
    },
    [theme.breakpoints.down('ss')]: {
      width: 23,
      height: 23,
    },
  },
  tooltip: {
    fontSize: '16px',
  },
}));

function ElevationScroll(props) {
  const { children, trigger } = props;

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

const PHONE_NUMBERS = [
  isAlcampo()
    ? { codes: ['ESP'], number: process.env.REACT_APP_PHONE_NUMBER }
    : ({ codes: ['FRA'], number: process.env.REACT_APP_PHONE_NUMBER },
      { codes: ['GLP', 'MTQ'], number: '(+590) 0 800 912 914' },
      { codes: ['REU'], number: '(+262) 0 800 905 193' }),
];

export default function ElevateAppBar(props) {
  const { t } = useTranslation(null, { keyPrefix: 'navbar' });
  const { children, homePage, dogCat } = props;
  const classes = useStyles({ dogCat });
  const [countryCode, setCountryCode] = useState('FR');
  const [openCalendly, setOpenCalendly] = useState(false);

  const triggerScroll = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });
  const trigger = homePage ? triggerScroll : true;

  axios
    .get('https://ipapi.co/json/')
    .then((response) => {
      let data = response.data;
      setCountryCode(data.country_code_iso3);
    })
    .catch((error) => {
      console.log(error);
    });

  const phoneNumber =
    PHONE_NUMBERS.find((e) => e.codes.indexOf(countryCode) !== -1)
      ?.number || process.env.REACT_APP_PHONE_NUMBER;
  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props} trigger={trigger}>
        <AppBar
          className={clsx([
            classes.appBar,
            trigger
              ? classes.appBarTrigger
              : classes.appBarNotTrigger,
          ])}
        >
          <Toolbar className={classes.toolbar}>
            <Grid container>
              <Grid item xs={4} sx={{ alignSelf: 'center' }}>
                <Hidden smUp>
                  <Drawer />
                </Hidden>
                <Hidden smDown>
                  <Typography
                    sx={{ fontSize: { sm: '16px', md: '18px' } }}
                    style={{ fontWeight: 'bold' }}
                    color="primary"
                  >
                    <Link
                      style={{ textDecoration: 'none' }}
                      data-cy="home-loginButton"
                      component={RouterLink}
                      to="/account/"
                    >
                      {t('accountButton')}{' '}
                      {isAlcampo() ? 'Alcampo Mascotas' : 'Drooky'}
                    </Link>
                  </Typography>
                </Hidden>
              </Grid>
              <Grid
                item
                xs={4}
                sx={
                  isAlcampo()
                    ? {
                        textAlign: 'center',
                        paddingTop: '1%',
                        alignSelf: 'center',
                      }
                    : { textAlign: 'center' }
                }
              >
                {!isAlcampo() && (
                  <Typography
                    color="primary"
                    variant="h4"
                    sx={{
                      fontSize: { xs: '25px', md: '38px' },
                    }}
                    className={classes.textLogo}
                  >
                    <Link
                      component={RouterLink}
                      to="/"
                      color="inherit"
                      style={{ textDecoration: 'none' }}
                    >
                      Drooky
                    </Link>
                  </Typography>
                )}
                {isAlcampo() && (
                  <Link component={RouterLink} to="/" color="inherit">
                    <img
                      className={classes.alcampoLogo}
                      src={alcampoLogo}
                      alt="alcampomascotas"
                    />
                  </Link>
                )}
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'inline-flex',
                  alignSelf: 'center',
                  justifyContent: 'right',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    right: { xs: '15px', md: undefined },
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Box
                    className={classes.logoContainer}
                    sx={{
                      display: {
                        xs: 'none',
                        sm: isAlcampo() ? 'none' : 'block',
                        md: isAlcampo() ? 'none' : 'block',
                      },
                    }}
                    onClick={() => {
                      window.open(
                        'https://www.tiktok.com/@drooky_family?lang=fr',
                        'tiktok',
                        'noopener',
                      );
                    }}
                  >
                    <img
                      src={tiktok_logo}
                      className={classes.logo}
                      alt="tiktok"
                    />
                  </Box>
                  <Box
                    className={classes.logoContainer}
                    sx={{
                      display: {
                        xs: 'none',
                        sm: isAlcampo() ? 'none' : 'block',
                        md: isAlcampo() ? 'none' : 'block',
                      },
                    }}
                    onClick={() => {
                      window.open(
                        'https://www.instagram.com/drooky_family/',
                        'instagram',
                        'noopener',
                      );
                    }}
                  >
                    <img
                      src={instagram_logo}
                      className={classes.logo}
                      alt="instagram"
                    />
                  </Box>
                  <Tooltip
                    enterTouchDelay={0}
                    title={t('mailUs')}
                    followCursor
                  >
                    <a
                      style={{
                        color: blue,
                        textDecoration: 'none',
                        paddingTop: '5px',
                        paddingRight: '8px',
                        paddingLeft: '8px',
                      }}
                      href={`mailto:${
                        isAlcampo()
                          ? process.env.REACT_APP_ALCAMPO_EMAIL
                          : process.env.REACT_APP_DROOKY_EMAIL
                      }`}
                    >
                      <MailOutlineIcon fontSize="large" />
                    </a>
                  </Tooltip>
                  <Box display={'flex'}>
                    <Hidden lgDown>
                      {isAlcampo() && (
                        <Button
                          sx={{
                            fontSize: {
                              sm: '14px',
                              md: '16px',
                            },
                            marginRight: '10px',
                            whiteSpace: 'nowrap',
                          }}
                          style={{
                            fontWeight: 'bold',
                            borderColor: blue,
                            border: '2px solid',
                          }}
                          color="primary"
                        >
                          <a
                            style={{
                              color: blue,
                              textDecoration: 'none',
                              display: 'flex',
                            }}
                            href={`tel:${phoneNumber}`}
                          >
                            {t('callUs')}
                            <Typography
                              sx={{
                                display: { xl: 'flex', lg: 'none' },
                                paddingLeft: '5px',
                                paddingTop: '2px',
                              }}
                            >
                              {phoneNumber}
                            </Typography>
                          </a>
                        </Button>
                      )}
                      <Button
                        sx={{
                          fontSize: { sm: '14px', md: '16px' },
                          whiteSpace: 'nowrap',
                        }}
                        style={{
                          fontWeight: 'bold',
                          color: yellow,
                          background: blue,
                        }}
                        onClick={() => setOpenCalendly(!openCalendly)}
                      >
                        <Typography style={{ fontWeight: 'bold' }}>
                          {t('bookAppointment')}
                        </Typography>
                      </Button>
                    </Hidden>
                    <CalendlyBooking
                      open={openCalendly}
                      setOpen={setOpenCalendly}
                    />
                  </Box>

                  {/* <div className={classes.logoContainer}>
                    <LanguageSelector />
                  </div> */}
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
              background: yellow,
              paddingY: 1,
            }}
          >
            <Hidden lgUp>
              {isAlcampo() && (
                <Button
                  sx={{
                    fontSize: { sm: '16px', md: '18px' },
                    marginRight: '10px',
                  }}
                  style={{
                    fontWeight: 'bold',
                    borderColor: blue,
                    border: '2px solid',
                  }}
                  color="primary"
                >
                  <a
                    style={{
                      color: blue,
                      textDecoration: 'none',
                    }}
                    href={`tel:${phoneNumber}`}
                  >
                    {t('callUs')}
                  </a>
                </Button>
              )}
              <Button
                sx={{
                  fontSize: { sm: '16px', md: '18px' },
                }}
                style={{
                  fontWeight: 'bold',
                  color: yellow,
                  background: blue,
                }}
                onClick={() => setOpenCalendly(!openCalendly)}
              >
                <Typography
                  sx={{
                    fontSize: { sm: '16px', md: '18px' },
                  }}
                  style={{ fontWeight: 'bold' }}
                >
                  {t('bookAppointment')}
                </Typography>
              </Button>
            </Hidden>
          </Box>
        </AppBar>
      </ElevationScroll>

      <Toolbar />

      <div className={classes.children}>{children}</div>
    </React.Fragment>
  );
}
