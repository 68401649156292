import ClearIcon from '@mui/icons-material/Clear';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SearchIcon from '@mui/icons-material/Search';
import {
  Grid,
  Hidden,
  ListSubheader,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { blue } from '../../../../theme';
import pricingTheme from '../../../../theme/pricing';
import { isAlcampo } from '../../../../utils/language';
import { DOG } from '../constants';

export default function Input({
  classes,
  breed,
  species,
  isMultiple,
  breedList,
  onChange,
  onCrossClick = () => {},
}) {
  const { t } = useTranslation(null, {
    keyPrefix: 'pricing.animalBreed',
  });

  const [breedSearch, setBreedSearch] = useState('');

  const filteredList = useMemo(() => {
    const list =
      breedList &&
      breedList
        .filter((el) => el.name !== 'croise')
        .filter((el) => el.species === species);
    if (breedSearch === '') return list;
    return _.filter(
      list,
      (el) =>
        el.translated_name
          .toLowerCase()
          .indexOf(breedSearch.toLowerCase()) > -1,
    );
  }, [breedList, breedSearch, species]);

  const crossId = useMemo(() => {
    return (
      breedList &&
      species &&
      breedList.find(
        (e) => e.name === 'croise' && e.species === species,
      ).id
    );
  }, [breedList, species]);

  const getIsCross = useMemo(() => {
    return breed ? crossId === breed : undefined;
  }, [crossId, breed]);

  const [isCross, setIsCross] = useState(getIsCross);

  useEffect(() => {
    setIsCross(getIsCross);
  }, [getIsCross]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isXs = width < 768;
  const isSs = width < 400;

  const handlePopoverClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <RadioGroup
        value={isCross}
        onChange={(event) => {
          const selected = event.target.value === 'true';
          setIsCross(selected);
          if (selected) {
            onCrossClick(crossId);
          }
        }}
        className={classes.firstInput}
        style={{
          marginBottom: 10,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <FormControlLabel
          value={true}
          style={{ zIndex: 100, marginLeft: 0 }}
          control={<Radio />}
          labelPlacement="bottom"
          label={
            <Typography className={classes.typo2}>
              {species === DOG ? t('input.dog') : t('input.cat')}{' '}
              {t('input.crossBreed')}
            </Typography>
          }
        />
        <FormControlLabel
          value={false}
          style={{ zIndex: 100, marginRight: 0 }}
          control={<Radio />}
          labelPlacement="bottom"
          label={
            <Typography
              className={classes.typo2}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
              }}
            >
              {species === DOG ? t('input.dog') : t('input.cat')}{' '}
              {t('input.pureBreed')}
              <Tooltip
                classes={{
                  tooltip: classes.tooltip,
                }}
                title={t('input.tooltip')}
                followCursor
              >
                <IconButton
                  style={{
                    color: pricingTheme.palette.primary.main,
                    marginLeft: '10px',
                    padding: 0,
                    marginBottom: isMultiple ? '2px' : 0,
                    display: isAlcampo() ? 'none' : 'flex',
                  }}
                  onClick={handlePopoverClick}
                >
                  {isMultiple ? (
                    <HelpOutlineIcon fontSize="small" />
                  ) : (
                    <>
                      <Hidden mdDown>
                        <HelpOutlineIcon fontSize="medium" />
                      </Hidden>
                      <Hidden mdUp>
                        <HelpOutlineIcon fontSize="small" />
                      </Hidden>
                    </>
                  )}
                </IconButton>
              </Tooltip>
              <Hidden mdUp>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Typography
                    color={'primary'}
                    style={{ padding: '10px', maxWidth: '400px' }}
                  >
                    {t('input.tooltip')}
                  </Typography>
                </Popover>
              </Hidden>
            </Typography>
          }
        />
      </RadioGroup>
      {isCross === false && (
        <div className={classes.secondInput}>
          <Typography variant={'h4'} className={classes.typo1}>
            {t('input.selectBreed')}
          </Typography>
          <FormControl style={{ width: '250px', marginTop: '30px' }}>
            <Select
              labelId="input-label"
              variant="standard"
              className={classes.select}
              MenuProps={{
                style: {
                  height: isXs ? '350px' : '500px',
                },
                anchorReference: isXs ? 'anchorPosition' : 'anchorEl',
                anchorPosition: { top: isSs ? 300 : 200, left: 0 },
              }}
              value={breed}
              onChange={(event) => {
                onChange(event.target.value);
              }}
            >
              <ListSubheader>
                <TextField
                  size="small"
                  value={breedSearch}
                  style={{ paddingTop: '10px' }}
                  autoFocus={false}
                  placeholder={t('input.placeholderSelectBreed')}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <ClearIcon
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setBreedSearch('');
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setBreedSearch(e.target.value)}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onKeyDown={(e) => {
                    if (e.key !== 'Escape') e.stopPropagation();
                  }}
                />
              </ListSubheader>
              {filteredList?.map((el) => (
                <MenuItem
                  onKeyDown={(e) => e.stopPropagation()}
                  style={{ color: blue }}
                  value={el.id}
                >
                  {el.translated_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    </Grid>
  );
}
