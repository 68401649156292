import FavoriteIcon from '@mui/icons-material/Favorite';
import { Grid, Hidden, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import pricing_asset_mobile_4 from '../../../../static/pricing/pricing-asset-mobile-4.svg';
import pricing_asset_mobile_emoticon from '../../../../static/pricing/pricing-asset-mobile-emoticon.svg';
import MultipleAnimalElement from './MultipleAnimalElement';

const useStyles = makeStyles((theme) => ({
  title_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      backgroundImage: `url(${pricing_asset_mobile_4})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  subtitle_text: {
    fontSize: '1.5rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem',
    },
  },
  mobile_image: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  formWrapper: {
    marginTop: 60,
    width: 950,
    [theme.breakpoints.down('lg')]: {
      width: 850,
    },
    [theme.breakpoints.down('md')]: {
      width: 700,
    },
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      marginTop: 40,
    },
  },
}));
export default function Pricing(props) {
  const { animals, control, register, t } = props;
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} className={classes.title_container}>
        <Hidden smDown>
          <Typography
            color={'primary'}
            variant={'h4'}
            className={classes.title_text}
          >
            {t('multiple.title')}
          </Typography>
        </Hidden>
        <Hidden smUp>
          <Typography
            color={'primary'}
            variant={'h4'}
            className={classes.title_text}
          >
            {t('multiple.mobile.title1')}
            <span style={{ display: 'inline-block' }}>
              {t('multiple.mobile.title2')}
            </span>
          </Typography>
        </Hidden>
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        alignItems="center"
        sx={{ marginTop: { xs: '10px', sm: '0px' } }}
      >
        <Hidden smUp>
          <Typography
            style={{
              fontSize: '1em',
              fontWeight: 'bold',
              paddingLeft: '15px',
              paddingRight: '15px',
            }}
            color="primary"
          >
            {t('multiple.mobile.subtitle1')}
            <span
              style={{ display: 'inline-flex', alignItems: 'center' }}
            >
              {t('multiple.mobile.subtitle2')}
              <Hidden smUp>
                <img
                  alt="emoticon"
                  style={{
                    width: '25px',
                    height: '25px',
                    marginLeft: '5px',
                  }}
                  src={pricing_asset_mobile_emoticon}
                />
              </Hidden>
            </span>
            <br />
            {t('multiple.mobile.subtitle3')}
          </Typography>
        </Hidden>
        <Hidden smDown>
          <Typography
            className={classes.subtitle_text}
            color="primary"
          >
            {t('multiple.subtitle1')}
            <br />
            {t('multiple.subtitle2')}
            <span
              style={{ display: 'inline-flex', alignItems: 'center' }}
            >
              {t('multiple.subtitle3')}
            </span>
            <FavoriteIcon
              style={{
                marginLeft: '10px',
                fontSize: 'inherit',
                color: '#d32d00',
                transform: 'translateY(3px)',
              }}
            />
          </Typography>
        </Hidden>
      </Grid>
      <Grid item xs={12} className={classes.formWrapper}>
        {animals.map((a, index) => (
          <MultipleAnimalElement
            key={`animalBirthday__${index}`}
            animal={a}
            index={index}
            animals={animals}
            control={control}
            register={register}
            t={t}
            {...props}
          />
        ))}
      </Grid>
    </>
  );
}
