import { MobileDatePicker } from '@mui/lab';
import { Grid, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { isAlcampo } from '../../../../utils/language';
import { animalsConfig } from '../animalName';
import { MIN_ANIMAL_BIRTHDAY_MONTHS } from '../constants';
import { selectAnimals } from '../pricingSlice';
import { calculateMaxAnimalAge } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: (props) => (props.last ? 0 : 30),
    justifyContent: 'center',
    alignItems: 'center',
    direction: 'column',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      direction: 'column',
      flexDirection: 'column',
    },
  },
  subtitle_text: {
    overflow: 'auto',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  icon: {
    height: 80,
    width: 80,
    marginRight: 40,
    [theme.breakpoints.down('lg')]: {
      height: 70,
      width: 70,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 30,
      marginLeft: 30,
    },
  },
  text_field_label: {
    color: theme.palette.primary.main,
  },
  date_picker: {
    width: 240,
    color: theme.palette.primary.main,
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '& input': {
      cursor: 'pointer',
    },
  },
}));

export default function Pricing(props) {
  const { animal, animals, control, index, t } = props;
  const dispatch = useDispatch();
  const animalConfig = animalsConfig[animal.species];
  const classes = useStyles({ last: index === animals?.length - 1 });
  const animal_birthday = animal?.birthday
    ? new Date(animal.birthday)
    : null;
  const [isOpen, setIsOpen] = React.useState(false);
  const onDateChanged = (date) => {
    const newAnimals = animals.map((a, i) =>
      i === index
        ? {
            ...a,
            birthday: date?.toISOString().slice(0, 10),
          }
        : a,
    );
    dispatch(selectAnimals(newAnimals));
  };
  const maxDate = new Date();
  const minDate = new Date();
  if (animal) {
    if (props.provider === 'wakam') {
      const maxAnimalBirthdayMonths = calculateMaxAnimalAge(animal);
      minDate.setMonth(
        minDate.getMonth() - maxAnimalBirthdayMonths,
        1,
      );
    } else {
      minDate.setMonth(
        minDate.getMonth() - (isAlcampo() ? 72 : 95),
        1,
      );
    }
    maxDate.setMonth(
      maxDate.getMonth() - MIN_ANIMAL_BIRTHDAY_MONTHS,
      1,
    );
  }
  return (
    <Grid container className={classes.root}>
      <Grid
        item
        xs={12}
        display="flex"
        direction="column"
        alignItems="center"
      >
        <Grid
          style={{ marginBottom: '10px', width: '100%' }}
          sx={{ justifyContent: { xs: 'center', sm: 'flex-start' } }}
          display="flex"
          alignItems="center"
        >
          <Typography
            className={classes.subtitle_text}
            color="primary"
          >
            {`${animal.name
              .charAt(0)
              .toUpperCase()}${animal.name.slice(1)}`}
          </Typography>
        </Grid>
        <Grid
          container
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <img
            className={classes.icon}
            src={animalConfig.icon}
            alt={'img'}
          />
          <Controller
            control={control}
            name={`animalBirthday_${index}`}
            defaultValue={animal_birthday}
            render={({ field }) => {
              const handleChange = (date) => {
                if (
                  !(date instanceof Date) ||
                  isNaN(date) ||
                  date < new Date('1980-01-01') ||
                  date > new Date()
                )
                  return;
                field.onChange(date);
                onDateChanged(date);
              };
              return (
                <MobileDatePicker
                  views={['year', 'month']}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      InputLabelProps={{
                        className: classes.text_field_label,
                      }}
                      label={t('labelBirthday')}
                    />
                  )}
                  open={isOpen}
                  onAccept={() => setIsOpen(false)}
                  onClose={() => setIsOpen(false)}
                  onOpen={() => {
                    setIsOpen(true);
                  }}
                  cancelText={t('cancel')}
                  okText={'Ok'}
                  onMonthChange={(date) => {
                    handleChange(date);
                    setIsOpen(false);
                  }}
                  onYearChange={(date) => handleChange(date)}
                  onChange={(date) => handleChange(date)}
                  showToolbar={false}
                  minDate={minDate}
                  maxDate={maxDate}
                  {...field}
                  InputProps={{ className: classes.date_picker }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '20px' }}>
          <Typography
            color="primary"
            style={{
              fontSize: '1.1em',
              textAlign: 'left',
              paddingLeft: '30px',
              paddingRight: '30px',
            }}
          >
            {t('tips.ageWarning1', { animalName: animal.name })}
            <span style={{ display: 'inline-block' }}>
              {props.provider === 'wakam'
                ? Math.ceil(calculateMaxAnimalAge(animal) / 12)
                : isAlcampo()
                ? 6
                : 8}{' '}
              {t('tips.ageWarning2')}
            </span>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
