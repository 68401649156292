import { exists, t } from 'i18next';

export const isAlcampo = () =>
  process.env.REACT_APP_LANGUAGE === 'es';

export const getAppName = () =>
  isAlcampo() ? 'alcampomascotas' : 'drooky';

const getTranslationKeyFromApiResponse = (apiResponse) =>
  apiResponse?.error_type &&
  exists(`errorsMessages.${apiResponse?.error_type}`)
    ? apiResponse?.error_type
    : 'generic_error';

export const getErrorTranslation = (
  apiResponse = null,
  parameters = {},
) =>
  t(
    `errorsMessages.${getTranslationKeyFromApiResponse(apiResponse)}`,
    parameters,
  );

export const getStripeErrorTranslation = (code) => t(`stripe.errorMessages.${code}`);

const methods = {
  isAlcampo,
  getErrorTranslation,
  getStripeErrorTranslation,
  getAppName,
};
export default methods;
