import React from 'react';
import {makeStyles} from "@mui/styles";

import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme)=>(
    {
        'root':{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& .MuiPopover-paper': {
            textAlign: 'center',
            position:'relative',
            top:'0 !important',
            left:'0 !important',
            padding: 20,
            width: '50%',
            height: '50%',
            [theme.breakpoints.down('xl')]: {
              width: '80%',
              height: '80%',
            },
          }
        },
        'button':{
          display: 'flex',
          minWidth: '10px',
          width: '10px',
          height: '30px',
          borderRadius: '30px'
        }
    })
)

export default function BasicPopover({style, children, rootClass}) {

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClick = (event) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{display:'inline-flex', marginLeft:'10px', ...style}}>
      <Button aria-describedby='simple-popover' variant="contained" onClick={handleClick} className={classes.button}>
        ?
      </Button>
      <Popover
        id='simple-popover'
        open={open}
        onClose={handleClose}
        className={rootClass || classes.root}>
        {children}
      </Popover>
    </div>
  );
}