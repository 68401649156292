import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material//Button';
import Divider from '@mui/material//Divider';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';

import { Context } from '../../../layouts/Account';
import ange from '../../../static//sidebar/ange.svg';
import logoAlcampo from '../../../static/alcampo/logo.svg';
import logoDrooky from '../../../static/logo.svg';
import card from '../../../static/sidebar/card.svg';
import conseiller from '../../../static/sidebar/conseiller.svg';
import contracts from '../../../static/sidebar/contracts.svg';
import home from '../../../static/sidebar/home.svg';
import options from '../../../static/sidebar/options.svg';
import devis from '../../../static/sidebar/paper.svg';
import remb from '../../../static/sidebar/remb.svg';
import coord from '../../../static/sidebar/runner.svg';
import send from '../../../static/sidebar/send.svg';
import services from '../../../static/sidebar/services.svg';
import { yellow } from '../../../theme';
import { isAlcampo } from '../../../utils/language';
import { logoutUser } from '../../axiosApi';
import NotificationBell from './notifications/Bell';
import { ListItemButton } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  toolbar: {
    marginLeft: 'auto',
    display: 'flex',
    textTransform: 'none',
    paddingLeft: '10px',
  },
  logo: {
    width: (props) => props.drawerWidth / 3,
    marginRight: 20,
    textDecoration: 'none',
  },
  drawer: {
    width: (props) => props.drawerWidth,
    flexShrink: 0,
    '& > *': {
      color: theme.palette.primary.main,
    },
  },
  drawerPaper: {
    width: (props) => props.drawerWidth,
    background: 'white',
  },
  drawerContainer: {
    marginTop: (props) =>
      props.isMobileApp ? 'env(safe-area-inset-top)' : 'unset',
    '& .MuiListItem-root:not(:first-child):not(:last-child):is(.active)':
      {
        '& span': {
          fontWeight: 'bold',
        },
        backgroundColor: yellow,
      },
  },
  content: {
    minHeight: '100%',
    flexGrow: 1,
    padding: 0,
    backgroundColor: '#f5f5f5',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  item: {
    '&>span': {
      fontSize: 15,
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      '&>span': {
        fontSize: 13.5,
      },
    },
  },
  btn: {
    width: '90%',
    margin: '10px',
    background: theme.palette.primary.main,
    color: 'white',
    '&:disabled': {
      opacity: 0,
    },
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
  },
  active: {
    background: theme.palette.secondary.main,
  },
  text_3: {
    fontSize: 13,
    marginTop: 10,
    margin: 'auto',
    opacity: 0.3,
  },
  carousel: {
    '&>div>rec-dot': {
      background: '#ff4343',
      border: '2px #333 solid',
      borderRadius: 0,
    },
    'rec-dot_active': {
      background: '#59fd59',
    },
  },

  img: {
    width: 35,
    height: 35,
  },
}));

export const DrawerContent = (props) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.drawer',
  });
  const logo = isAlcampo() ? logoAlcampo : logoDrooky;
  const [drawerWidth, setDrawerWidth] = React.useState(
    window.innerWidth > 1200
      ? 340
      : window.innerWidth > 768
      ? 300
      : 270,
  );
  React.useEffect(() => {
    const handleResizeWindow = () => {
      if (window.innerWidth <= 768 && drawerWidth !== 260)
        setDrawerWidth(260);
      else if (
        window.innerWidth > 768 &&
        window.innerWidth <= 1200 &&
        drawerWidth !== 300
      )
        setDrawerWidth(300);
      else if (window.innerWidth > 1200 && drawerWidth !== 340)
        setDrawerWidth(340);
    };
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, [drawerWidth]);
  const isMobileApp = process.env.REACT_APP_IS_MOBILE_APP === 'true';
  const classes = useStyles({ drawerWidth, isMobileApp });
  const { openModal, data, handleClose } = useContext(Context);
  const guardian =
    data?.contracts?.filter(
      (contract, index) => contract.contractType === 'guard',
    ).length !== 0 ||
    data?.contracts?.filter(
      (contract, index) => contract.option === true,
    ).length !== 0;
  const [width, setWidth] = React.useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  return (
    <>
      <div className={classes.drawerContainer}>
        <List>
          <ListItem disabled={props.disabled} key="notif">
            <ListItem
              disabled={props.disabled}
              sx={{ width: 'calc(100% - 45px)' }}
              button
              component={RouterLink}
              to="/account/"
              key="logo"
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: { xs: '50px', md: '70px' },
                }}
              >
                <img
                  style={
                    isAlcampo()
                      ? { width: '100%' }
                      : { height: '100%' }
                  }
                  src={logo}
                  alt={'Accueil'}
                />
                {!isAlcampo() && (
                  <Typography
                    color="primary"
                    variant="h5"
                    style={{ fontSize: '25px' }}
                    sx={{ marginLeft: { xs: '7px', md: '14px' } }}
                  >
                    {t('companyName')}
                  </Typography>
                )}
              </Box>
              {/*<img
                className={classes.logo}
                src={logo}
                alt={'Accueil'}
              />
              <Typography
                color="primary"
                variant="h5"
                style={{ fontSize: '25px' }}
              >
                drooky
              </Typography>*/}
            </ListItem>

            <NotificationBell
              disabled={props.disabled}
              data={data}
              openModal={openModal}
              close={handleClose}
            />
            {/* <SelectLanguage disabled={props.disabled} data={data} /> */}
          </ListItem>

          <Divider />

          <ListItem
            disabled={props.disabled}
            style={{ padding: width < 1200 ? '8px 4px 8px 6px' : '' }}
            button
            key="home"
            component={RouterLink}
            to="/account/"
          >
            <ListItemIcon
              style={{ minWidth: width < 1200 ? '45px' : '' }}
            >
              <img
                className={classes.img}
                src={home}
                alt={'Accueil'}
              />
            </ListItemIcon>
            <ListItemText
              primary={t('listItem.home')}
              className={classes.item}
            />
          </ListItem>

          {data?.default_contract?.paymentDue ? (
            <Tooltip
              key={'refundtooltip'}
              title={
                <Typography fontSize={16}>
                  {t('listItem.paymentDue')}
                </Typography>
              }
              arrow
            >
              <span>
                <ListItem
                  disabled={
                    props.disabled ||
                    data?.default_contract?.paymentDue
                  }
                  button
                  style={{
                    padding: width < 1200 ? '8px 4px 8px 6px' : '',
                  }}
                  key="remb"
                  component={RouterLink}
                  to="/account/refund"
                >
                  <ListItemIcon
                    style={{ minWidth: width < 1200 ? '45px' : '' }}
                  >
                    <img
                      className={classes.img}
                      src={services}
                      alt={'Remboursement'}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('listItem.askRefund')}
                    className={classes.item}
                  />
                </ListItem>
              </span>
            </Tooltip>
          ) : (
            <ListItemButton
              disabled={
                props.disabled || data?.default_contract?.paymentDue
              }
              style={{
                padding: width < 1200 ? '8px 4px 8px 6px' : '',
              }}
              key="remb"
              component={RouterLink}
              to="/account/refund"
            >
              <ListItemIcon
                style={{ minWidth: width < 1200 ? '45px' : '' }}
              >
                <img
                  className={classes.img}
                  src={services}
                  alt={'Remboursement'}
                />
              </ListItemIcon>
              <ListItemText
                primary={t('listItem.askRefund')}
                className={classes.item}
              />
            </ListItemButton>
          )}

          <ListItemButton
            disabled={props.disabled}
            style={{ padding: width < 1200 ? '8px 4px 8px 6px' : '' }}
            component={RouterLink}
            to="/account/myrefunds"
            key="histoR"
          >
            <ListItemIcon
              style={{ minWidth: width < 1200 ? '45px' : '' }}
            >
              <img
                className={classes.img}
                src={remb}
                alt={'Historique Remboursements'}
              />
            </ListItemIcon>
            <ListItemText
              primary={t('listItem.historyRefund')}
              className={classes.item}
            />
          </ListItemButton>

          {data?.default_contract?.paymentDue ? (
            <Tooltip
              key={'quotetooltip'}
              title={
                <Typography fontSize={16}>
                  {t('listItem.paymentDue')}
                </Typography>
              }
              arrow
            >
              <span>
                <ListItemButton
                  disabled={
                    props.disabled ||
                    data?.default_contract?.paymentDue
                  }
                  style={{
                    padding: width < 1200 ? '8px 4px 8px 6px' : '',
                  }}
                  component={RouterLink}
                  to="/account/quote"
                  key="devis"
                >
                  <ListItemIcon
                    style={{ minWidth: width < 1200 ? '45px' : '' }}
                  >
                    <img
                      className={classes.img}
                      src={send}
                      alt={'Devis'}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('listItem.askQuote')}
                    className={classes.item}
                  />
                </ListItemButton>
              </span>
            </Tooltip>
          ) : (
            <ListItem
              disabled={
                props.disabled || data?.default_contract?.paymentDue
              }
              button
              style={{
                padding: width < 1200 ? '8px 4px 8px 6px' : '',
              }}
              component={RouterLink}
              to="/account/quote"
              key="devis"
            >
              <ListItemIcon
                style={{ minWidth: width < 1200 ? '45px' : '' }}
              >
                <img
                  className={classes.img}
                  src={send}
                  alt={'Devis'}
                />
              </ListItemIcon>
              <ListItemText
                primary={t('listItem.askQuote')}
                className={classes.item}
              />
            </ListItem>
          )}

          <ListItem
            disabled={props.disabled}
            button
            style={{ padding: width < 1200 ? '8px 4px 8px 6px' : '' }}
            component={RouterLink}
            to="/account/myquotes"
            key="histoQ"
          >
            <ListItemIcon
              style={{ minWidth: width < 1200 ? '45px' : '' }}
            >
              <img
                className={classes.img}
                src={devis}
                alt={'Historique Devis'}
              />
            </ListItemIcon>
            <ListItemText
              primary={t('listItem.checkQuote')}
              className={classes.item}
            />
          </ListItem>

          <Divider />

          <ListItem
            disabled={props.disabled}
            button
            style={{ padding: width < 1200 ? '8px 4px 8px 6px' : '' }}
            component={RouterLink}
            to="/account/advisor"
            key="conseiller"
          >
            <ListItemIcon
              style={{ minWidth: width < 1200 ? '45px' : '' }}
            >
              <img
                className={classes.img}
                src={conseiller}
                alt={'Conseiller'}
              />
            </ListItemIcon>
            <ListItemText
              primary={t('listItem.contactAdvisor')}
              className={classes.item}
            />
          </ListItem>

          {data?.default_contract?.paymentDue ? (
            <Tooltip
              key={'garantiestooltip'}
              title={
                <Typography fontSize={16}>
                  {t('listItem.paymentDue')}
                </Typography>
              }
              arrow
            >
              <span>
                <ListItemButton
                  disabled={
                    props.disabled ||
                    data?.default_contract?.paymentDue
                  }
                  style={{
                    padding: width < 1200 ? '8px 4px 8px 6px' : '',
                  }}
                  component={RouterLink}
                  to="/account/garanties"
                  key="garanties"
                >
                  <ListItemIcon
                    style={{ minWidth: width < 1200 ? '45px' : '' }}
                  >
                    <img
                      className={classes.img}
                      src={options}
                      alt={'Garanties'}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('listItem.modifyWaranty')}
                    className={classes.item}
                  />
                </ListItemButton>
              </span>
            </Tooltip>
          ) : (
            <ListItemButton
              disabled={
                props.disabled || data?.default_contract?.paymentDue
              }
              style={{
                padding: width < 1200 ? '8px 4px 8px 6px' : '',
              }}
              component={RouterLink}
              to="/account/garanties"
              key="garanties"
            >
              <ListItemIcon
                style={{ minWidth: width < 1200 ? '45px' : '' }}
              >
                <img
                  className={classes.img}
                  src={options}
                  alt={'Garanties'}
                />
              </ListItemIcon>
              <ListItemText
                primary={t('listItem.modifyWaranty')}
                className={classes.item}
              />
            </ListItemButton>
          )}

          {data?.default_contract?.paymentDue ? (
            <Tooltip
              key={'contractstooltip'}
              title={
                <Typography fontSize={16}>
                  {t('listItem.paymentDue')}
                </Typography>
              }
              arrow
            >
              <span>
                <ListItemButton
                  disabled={
                    props.disabled ||
                    data?.default_contract?.paymentDue
                  }
                  style={{
                    padding: width < 1200 ? '8px 4px 8px 6px' : '',
                  }}
                  component={RouterLink}
                  to="/account/contracts"
                  key="contracts"
                >
                  <ListItemIcon
                    style={{ minWidth: width < 1200 ? '45px' : '' }}
                  >
                    <img
                      className={classes.img}
                      src={contracts}
                      alt={'Contracts'}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('listItem.myContracts')}
                    className={classes.item}
                  />
                </ListItemButton>
              </span>
            </Tooltip>
          ) : (
            <ListItemButton
              disabled={
                props.disabled || data?.default_contract?.paymentDue
              }
              style={{
                padding: width < 1200 ? '8px 4px 8px 6px' : '',
              }}
              component={RouterLink}
              to="/account/contracts"
              key="contracts"
            >
              <ListItemIcon
                style={{ minWidth: width < 1200 ? '45px' : '' }}
              >
                <img
                  className={classes.img}
                  src={contracts}
                  alt={'Contracts'}
                />
              </ListItemIcon>
              <ListItemText
                primary={t('listItem.myContracts')}
                className={classes.item}
              />
            </ListItemButton>
          )}

          {guardian ? (
            <ListItemButton
              disabled={props.disabled}
              style={{
                padding: width < 1200 ? '8px 4px 8px 6px' : '',
              }}
              component={RouterLink}
              to="/account/myguardian"
              key="guardian"
            >
              <ListItemIcon
                style={{ minWidth: width < 1200 ? '45px' : '' }}
              >
                <img
                  className={classes.img}
                  src={ange}
                  alt={'Gardien'}
                />
              </ListItemIcon>
              <ListItemText
                primary={t('listItem.myGuardian')}
                className={classes.item}
              />
            </ListItemButton>
          ) : (
            ''
          )}

          <ListItemButton
            disabled={props.disabled}
            style={{ padding: width < 1200 ? '8px 4px 8px 6px' : '' }}
            component={RouterLink}
            to="/account/payment"
            key="payment"
          >
            <ListItemIcon
              style={{ minWidth: width < 1200 ? '45px' : '' }}
            >
              <img
                className={classes.img}
                src={card}
                alt={'Paiements'}
              />
            </ListItemIcon>
            <ListItemText
              primary={t('listItem.paymentMethod')}
              className={classes.item}
            />
          </ListItemButton>

          <ListItemButton
            disabled={props.disabled}
            style={{ padding: width < 1200 ? '8px 4px 8px 6px' : '' }}
            component={RouterLink}
            to="/account/personnalinfo"
            key="admin"
          >
            <ListItemIcon
              style={{ minWidth: width < 1200 ? '45px' : '' }}
            >
              <img
                className={classes.img}
                src={coord}
                alt={'Administration'}
              />
            </ListItemIcon>
            <ListItemText
              primary={t('listItem.personnalInfo')}
              className={classes.item}
            />
          </ListItemButton>

          <Divider />
          <ListItem disabled={props.disabled} key="deco">
            <Button
              disabled={props.disabled}
              onClick={() => logoutUser()}
              className={classes.btn}
            >
              {t('listItem.logoutButton')}
            </Button>
          </ListItem>
        </List>
      </div>
    </>
  );
};

export default function AccountLayout(props) {
  const classes = useStyles();

  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (status) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpenDrawer(status);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <IconButton
        color="primary"
        edge="start"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        size="large"
      >
        style={{ padding: 8 }}
        <MenuIcon />
      </IconButton>
      <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
        <DrawerContent classes={classes} />
      </Drawer>
    </div>
  );
}
