import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import logoAlcampo from '../../../static/alcampo/logo.svg';
import logoEleveursOnline from '../../../static/logo-eleveurs-online.png';
import { blue, blue_light } from '../../../theme';

const heightDesktop = 600;
const heightMobile = 300;
const minZoomDesktop = 1000;

const useStyles = makeStyles((theme) => ({
  footerSection: {
    marginTop: '-5px',
    position: 'relative',
    background: blue_light,
    height: heightMobile,
    overflow: 'hidden',
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: heightDesktop,
    },
  },
}));

export default function FooterAlcampo() {
  const { t } = useTranslation(null, { keyPrefix: 'footer' });
  const navigate = useNavigate();
  const logo = logoAlcampo;

  const redirect = (page) => {
    navigate(`/${page}`);
  };

  const classes = useStyles();
  return (
    <section id="footer" className={classes.footerSection}>
      <Box
        sx={{
          marginTop: { xs: '30px', md: '70px' },
          mx: '5%',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Box style={{ textAlign: 'left' }}>
          <Box
            component="img"
            src={logo}
            sx={{
              width: '20%',
            }}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={3}>
              <Typography
                color="primary"
                sx={{
                  lineHeight: { xs: '10px', md: '36px' },
                  fontSize: { xs: '10px', md: '20px' },
                  textAlign: 'left',
                }}
              >
                ALCAMPO MASCOTAS (TODAY Assurances)
                <br />
                C. de Velázquez, 34
                <br />
                Salamanca, 28001 Madrid
                <br />
                RCS Paris B 893 977 124 <br />
                SIREN 893977124 - ORIAS 21006074 <br />
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ display: 'none' }}>
              <Typography
                variant={'h3'}
                sx={{
                  lineHeight: { xs: '17.25px', md: '60px' },
                  mb: { xs: '20px', md: '30px' },
                }}
              >
                {t('partners')}
              </Typography>
              <img
                src={logoEleveursOnline}
                onClick={() =>
                  window.open(
                    'https://www.eleveurs-online.com',
                    '_blank',
                    'noopener',
                  )
                }
                alt="eleveurs-online"
                style={{
                  width: '60%',
                  height: '60%',
                  cursor: 'pointer',
                }}
              />
              <br />
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant={'h3'}
                sx={{
                  lineHeight: { xs: '17.25px', md: '60px' },
                  mb: { xs: '20px', md: '30px' },
                }}
              >
                {t('usefulLinks.title')}
              </Typography>
              <Button
                style={{ textTransform: 'none' }}
                onClick={() => redirect('aboutUs')}
              >
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  {t('usefulLinks.aboutUs')}
                </Typography>
              </Button>
              <br />
              <Button style={{ textTransform: 'none' }}>
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  <a
                    style={{ color: blue, textDecoration: 'none' }}
                    href={`mailto:${process.env.REACT_APP_ALCAMPO_EMAIL}`}
                  >
                    {t('usefulLinks.contact')}
                  </a>
                </Typography>
              </Button>
              <br />
              <Button
                style={{
                  textTransform: 'none',
                  display: 'none',
                }}
                onClick={() => {
                  window.open(
                    'https://www.tiktok.com/@drooky_family?lang=fr',
                    'tiktok',
                    'noopener',
                  );
                }}
              >
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  {t('usefulLinks.tiktok')}
                </Typography>
              </Button>
              <br />
              <Button
                style={{
                  textTransform: 'none',
                  display: 'none',
                }}
                onClick={() => {
                  window.open(
                    'https://www.instagram.com/drooky_family/',
                    'instagram',
                    'noopener',
                  );
                }}
              >
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  {t('usefulLinks.instagram')}
                </Typography>
              </Button>
            </Grid>

            <Grid item xs={3}>
              <Typography
                variant={'h3'}
                sx={{
                  lineHeight: { xs: '17.25px', md: '60px' },
                  mb: { xs: '20px', md: '30px' },
                }}
              >
                {t('boredLinks.title')}
              </Typography>
              <Button
                style={{ textTransform: 'none' }}
                onClick={() => redirect('privacyPolicy')}
              >
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  {t('boredLinks.terms')}
                </Typography>
              </Button>
              <br />
              <Button
                style={{ textTransform: 'none' }}
                onClick={() => redirect('legalNotice')}
              >
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  {t('boredLinks.legalNotice')}
                </Typography>
              </Button>
              <br />
            </Grid>
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: { xs: '30px', md: '70px' },
          mx: '5%',
          display: { xs: 'block', md: 'none' },
        }}
      >
        <Box style={{ textAlign: 'left' }}>
          <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
            <Grid item xs={4}>
              <img
                src={logo}
                alt="Alcampo Mascotas"
                style={{
                  width: '120px',
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant={'h3'}
                sx={{
                  lineHeight: { xs: '17.25px', md: '60px' },
                  mb: { xs: '20px', md: '30px' },
                }}
              >
                Alcampomascotas.com
              </Typography>
              <Typography
                color="primary"
                sx={{
                  lineHeight: { xs: '10px', md: '36px' },
                  fontSize: { xs: '10px', md: '20px' },
                  textAlign: 'left',
                }}
              >
                ALCAMPO MASCOTAS (TODAY Assurances)
                <br />
                C. de Velázquez, 34
                <br />
                Salamanca, 28001 Madrid
                <br />
                RCS Paris B 893 977 124 <br />
                SIREN 893977124 - ORIAS 21006074 <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: '30px' }}>
            <Grid item xs={6}>
              <Typography
                variant={'h3'}
                sx={{
                  lineHeight: { xs: '17.25px', md: '60px' },
                  mb: { xs: '20px', md: '30px' },
                }}
              >
                {t('usefulLinks.title')}
              </Typography>
              <Button
                style={{ textTransform: 'none' }}
                onClick={() => redirect('aboutUs')}
              >
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  {t('usefulLinks.aboutUs')}
                </Typography>
              </Button>
              <br />
              <Button style={{ textTransform: 'none' }}>
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  <a
                    style={{ color: blue, textDecoration: 'none' }}
                    href={`mailto:${process.env.REACT_APP_ALCAMPO_EMAIL}`}
                  >
                    {t('usefulLinks.contact')}
                  </a>
                </Typography>
              </Button>
              <br />
              <Button
                style={{
                  textTransform: 'none',
                  display: 'none',
                }}
                onClick={() => {
                  window.open(
                    'https://www.tiktok.com/@drooky_family?lang=fr',
                    'tiktok',
                    'noopener',
                  );
                }}
              >
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  {t('usefulLinks.tiktok')}
                </Typography>
              </Button>
              <br />
              <Button
                style={{
                  textTransform: 'none',
                  display: 'none',
                }}
                onClick={() => {
                  window.open(
                    'https://www.instagram.com/drooky_family/',
                    'instagram',
                    'noopener',
                  );
                }}
              >
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  {t('usefulLinks.instagram')}
                </Typography>
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant={'h3'}
                sx={{
                  lineHeight: { xs: '17.25px', md: '60px' },
                  mb: { xs: '20px', md: '30px' },
                }}
              >
                {t('boredLinks.title')}
              </Typography>
              <Button
                style={{ textTransform: 'none' }}
                onClick={() => redirect('privacyPolicy')}
              >
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  {t('boredLinks.terms')}
                </Typography>
              </Button>
              <br />
              <Button
                style={{ textTransform: 'none' }}
                onClick={() => redirect('legalNotice')}
              >
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  {t('boredLinks.legalNotice')}
                </Typography>
              </Button>
              <br />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </section>
  );
}
