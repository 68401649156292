import { createTheme } from "@mui/material/styles";
import { themeCore, blue, blue_light, yellow } from "./index";

const pricingTheme = createTheme({
  ...themeCore,
  palette: {
    primary: {
      main: blue,
      contrastText: yellow,
    },
    secondary: {
      main: blue_light,
    },
    background: {
      default: blue_light,
    },
  },
  components: {
    ...themeCore.components,
  },
});

export default pricingTheme;
