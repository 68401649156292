import { Outlet } from 'react-router-dom';
import Navbar from '../components/templates/pricing/Navbar';
import pricingTheme from "../theme/pricing";
import { ThemeProvider } from '@mui/material/styles';

export default function PricingLayout(props) {
    return (
        <ThemeProvider theme={pricingTheme}>
            <Navbar {...props}>
                <Outlet />
            </Navbar>
        </ThemeProvider>
    );
}
