import { createTheme } from '@mui/material/styles';
import SupplyWoff2 from '../static/supply-bold.woff';

export const yellow = '#f8e873ff';
export const blue = '#014c7eff';
export const blue_light = '#eaf1f4';

export const breakpoints = {
  values: {
    xs: 0,
    ss: 400,
    sm: 768,
    md: 1000,
    lg: 1200,
    xl: 1536,
  },
};

const themeInit = createTheme({ breakpoints });

const isAppleDevice = navigator.vendor
  .toLowerCase()
  .includes('apple');
export const themeCore = {
  breakpoints,
  palette: {
    primary: {
      main: blue,
      contrastText: yellow,
    },
    secondary: {
      main: yellow,
    },
    background: {
      default: 'white',
    },
  },
  typography: {
    fontFamily: 'Supply',
    fontWeight: 'bold !important',
    h1: {
      zIndex: 100,
      fontWeight: 'bold !important',
      fontSize: 65,
      lineHeight: '85px',
      [themeInit.breakpoints.down('md')]: {
        fontSize: 25,
        lineHeight: 'inherit',
      },
    },
    h2: {
      zIndex: 100,
      fontWeight: 'bold !important',
      fontSize: 53,
      // lineHeight:60
      [themeInit.breakpoints.down('md')]: {
        fontSize: 20,
      },
    },
    h3: {
      zIndex: 100,
      fontWeight: 'bold !important',
      fontSize: 42,
      [themeInit.breakpoints.down('md')]: {
        fontSize: 17,
      },
      // lineHeight:60
    },
    h4: {
      zIndex: 100,
      fontWeight: 'bold !important',
      [themeInit.breakpoints.down('md')]: {
        fontSize: 15,
      },
    },
    h5: {
      zIndex: 100,
      fontWeight: 'bold !important',
      fontSize: 25,
      [themeInit.breakpoints.down('md')]: {
        fontSize: 13,
      },
    },
    h6: {
      zIndex: 100,
      fontWeight: 'bold !important',
      fontSize: 20,
      [themeInit.breakpoints.down('md')]: {
        fontSize: 13,
      },
    },
    button: {
      fontWeight: 'bold !important',
      color: yellow,
      fontSize: 14,
      [themeInit.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
  },

  components: {
    MuiMonthPicker: {
      styleOverrides: {
        root: {
          button: {
            color: 'black',
            background: 'none',
            border: 'none',
          },
          '& .Mui-selected': {
            fontSize: 25,
          },
        },
      },
    },
    MuiYearPicker: {
      styleOverrides: {
        root: {
          '& .Mui-selected': {
            color: `${yellow} !important`,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
            @font-face {
                font-family: 'Supply';
                font-style: normal;
                font-display: swap;
                font-weight: ${isAppleDevice ? '900' : '1100'};
                src: local('Supply'), local('Supply-Regular'), url(${SupplyWoff2}) format('woff2');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;  
             }`,
    },
  },
};

const theme = createTheme(themeCore);

export default theme;
