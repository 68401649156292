import FavoriteIcon from '@mui/icons-material/Favorite';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import instagram_logo from '../../../static/home/instagram_logo.svg';
import tiktok_logo from '../../../static/home/tiktok_logo.svg';
import logoEleveursOnline from '../../../static/logo-eleveurs-online.png';
import logoDrooky from '../../../static/logo.svg';
import { blue, blue_light } from '../../../theme';

const heightDesktop = 600;
const heightMobile = 300;
const minZoomDesktop = 1000;

const useStyles = makeStyles((theme) => ({
  footerSection: {
    marginTop: '-5px',
    position: 'relative',
    background: blue_light,
    height: heightMobile,
    overflow: 'hidden',
    [theme.breakpoints.up(minZoomDesktop)]: {
      height: heightDesktop,
    },
  },
}));

export default function FooterDrooky() {
  const { t } = useTranslation(null, { keyPrefix: 'footer' });
  const navigate = useNavigate();
  const logo = logoDrooky;

  const redirect = (page) => {
    navigate(`/${page}`);
  };

  const classes = useStyles();
  return (
    <section id="footer" className={classes.footerSection}>
      <Box
        sx={{
          marginTop: { xs: '30px', md: '70px' },
          mx: '5%',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Box style={{ textAlign: 'left' }}>
          <Box
            component="img"
            src={logo}
            sx={{
              width: { xs: '48.3px', md: '168px' },
            }}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={3}>
              <Typography
                variant={'h3'}
                sx={{
                  lineHeight: { xs: '17.25px', md: '60px' },
                  mb: { xs: '20px', md: '30px' },
                }}
              >
                Drooky.com
              </Typography>

              <Typography
                color="primary"
                sx={{
                  lineHeight: { xs: '10px', md: '42px' },
                  fontSize: { xs: '10px', md: '20px' },
                  textAlign: 'left',
                }}
              >
                Drooky (TODAY Assurances SAS)
                <br />
                12, place des Victoires, Paris, 75002 <br />
                RCS Paris B 893 977 124 <br />
                SIREN 893977124 - ORIAS 21006074 <br />
              </Typography>
              <Typography
                color="primary"
                sx={{
                  marginTop: '26px',
                  lineHeight: { xs: '10px', md: '42px' },
                  fontSize: { xs: '10px', md: '20px' },
                  textAlign: 'left',
                }}
              >
                Made in Paris with{' '}
                <FavoriteIcon
                  style={{ color: '#d32d00' }}
                  sx={{
                    fontSize: { xs: '11px', md: 'inherit' },
                    transform: {
                      xs: 'translateY(2px)',
                      md: 'translateY(5px)',
                    },
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ display: 'block' }}>
              <Typography
                variant={'h3'}
                sx={{
                  lineHeight: { xs: '17.25px', md: '60px' },
                  mb: { xs: '20px', md: '30px' },
                }}
              >
                {t('partners')}
              </Typography>
              <img
                src={logoEleveursOnline}
                onClick={() =>
                  window.open(
                    'https://www.eleveurs-online.com',
                    '_blank',
                    'noopener',
                  )
                }
                alt="eleveurs-online"
                style={{
                  width: '60%',
                  height: '60%',
                  cursor: 'pointer',
                }}
              />
              <br />
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant={'h3'}
                sx={{
                  lineHeight: { xs: '17.25px', md: '60px' },
                  mb: { xs: '20px', md: '30px' },
                }}
              >
                {t('usefulLinks.title')}
              </Typography>
              <Button
                style={{ textTransform: 'none' }}
                onClick={() => redirect('aboutUs')}
              >
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  {t('usefulLinks.aboutUs')}
                </Typography>
              </Button>
              <br />
              <>
                <Button
                  style={{ textTransform: 'none' }}
                  onClick={() => redirect('partners')}
                >
                  <Typography
                    color="primary"
                    sx={{
                      lineHeight: { xs: '10px', md: '30px' },
                      fontSize: { xs: '10px', md: '20px' },
                      textAlign: 'left',
                    }}
                  >
                    {t('usefulLinks.ourPartners')}
                  </Typography>
                </Button>
                <br />
              </>
              <Button style={{ textTransform: 'none' }}>
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  <a
                    style={{ color: blue, textDecoration: 'none' }}
                    href={`mailto:${process.env.REACT_APP_DROOKY_EMAIL}`}
                  >
                    {t('usefulLinks.contact')}
                  </a>
                </Typography>
              </Button>
              <br />
              <Button
                style={{
                  textTransform: 'none',
                  display: 'flex',
                }}
                onClick={() => {
                  window.open(
                    'https://www.tiktok.com/@drooky_family?lang=fr',
                    'tiktok',
                    'noopener',
                  );
                }}
              >
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  {t('usefulLinks.tiktok')}
                </Typography>
              </Button>
              <br />
              <Button
                style={{
                  textTransform: 'none',
                  display: 'inline-flex',
                }}
                onClick={() => {
                  window.open(
                    'https://www.instagram.com/drooky_family/',
                    'instagram',
                    'noopener',
                  );
                }}
              >
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  {t('usefulLinks.instagram')}
                </Typography>
              </Button>
            </Grid>

            <Grid item xs={3}>
              <Typography
                variant={'h3'}
                sx={{
                  lineHeight: { xs: '17.25px', md: '60px' },
                  mb: { xs: '20px', md: '30px' },
                }}
              >
                {t('boredLinks.title')}
              </Typography>
              <Button
                style={{ textTransform: 'none' }}
                onClick={() => redirect('privacyPolicy')}
              >
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  {t('boredLinks.terms')}
                </Typography>
              </Button>
              <br />
              <Button
                style={{ textTransform: 'none' }}
                onClick={() => redirect('legalNotice')}
              >
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  {t('boredLinks.legalNotice')}
                </Typography>
              </Button>
              <br />
            </Grid>
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: '40px',
          mx: '5%',
          display: { xs: 'block', md: 'none' },
        }}
      >
        <Box style={{ textAlign: 'left' }}>
          <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
            <Grid item xs={4}>
              <img
                src={logo}
                alt="drooky"
                style={{
                  width: '90px',
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant={'h3'}
                sx={{
                  lineHeight: { xs: '17.25px', md: '60px' },
                  mb: { xs: '20px', md: '30px' },
                }}
              >
                Drooky.com
              </Typography>
              <Typography
                color="primary"
                sx={{
                  lineHeight: { xs: '10px', md: '42px' },
                  fontSize: { xs: '10px', md: '20px' },
                  textAlign: 'left',
                }}
              >
                Drooky (TODAY Assurances SAS)
                <br />
                12, place des Victoires, Paris, 75002 <br />
                RCS Paris B 893 977 124 <br />
                SIREN 893977124 - ORIAS 21006074 <br />
              </Typography>
              <Typography
                color="primary"
                sx={{
                  marginTop: '6px',
                  lineHeight: { xs: '10px', md: '42px' },
                  fontSize: { xs: '10px', md: '20px' },
                  textAlign: 'left',
                }}
              >
                Made in Paris with{' '}
                <FavoriteIcon
                  style={{ color: '#d32d00' }}
                  sx={{
                    fontSize: { xs: '11px', md: 'inherit' },
                    transform: {
                      xs: 'translateY(2px)',
                      md: 'translateY(5px)',
                    },
                  }}
                />
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
            <Grid item xs={4} sx={{ display: 'block' }}>
              <Typography
                variant={'h3'}
                sx={{
                  lineHeight: { xs: '17.25px', md: '60px' },
                  mb: { xs: '20px', md: '30px' },
                }}
              >
                {t('partners')}
              </Typography>
              <Box sx={{ textAlign: 'center' }}>
                <img
                  src={logoEleveursOnline}
                  onClick={() =>
                    window.open(
                      'https://www.eleveurs-online.com',
                      '_blank',
                      'noopener',
                    )
                  }
                  alt="eleveurs-online"
                  style={{
                    width: '90px',
                    cursor: 'pointer',
                  }}
                />
                <br />
                <img
                  src={tiktok_logo}
                  className={classes.logo}
                  alt="tiktok"
                  onClick={() => {
                    window.open(
                      'https://www.instagram.com/drooky_family/',
                      'instagram',
                      'noopener',
                    );
                  }}
                  style={{
                    width: '25px',
                    cursor: 'pointer',
                  }}
                />
                <br />
                <img
                  src={instagram_logo}
                  className={classes.logo}
                  alt="tiktok"
                  onClick={() => {
                    window.open(
                      'https://www.tiktok.com/@drooky_family?lang=fr',
                      'tiktok',
                      'noopener',
                    );
                  }}
                  style={{
                    width: '25px',
                    cursor: 'pointer',
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant={'h3'}
                sx={{
                  lineHeight: { xs: '17.25px', md: '60px' },
                  mb: { xs: '20px', md: '30px' },
                }}
              >
                {t('usefulLinks.title')}
              </Typography>
              <Button
                style={{ textTransform: 'none' }}
                onClick={() => redirect('aboutUs')}
              >
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  {t('usefulLinks.aboutUs')}
                </Typography>
              </Button>
              <br />
              <>
                <Button
                  style={{ textTransform: 'none' }}
                  onClick={() => redirect('partners')}
                >
                  <Typography
                    color="primary"
                    sx={{
                      lineHeight: { xs: '10px', md: '30px' },
                      fontSize: { xs: '10px', md: '20px' },
                      textAlign: 'left',
                    }}
                  >
                    {t('usefulLinks.ourPartners')}
                  </Typography>
                </Button>
                <br />
              </>
              <Button style={{ textTransform: 'none' }}>
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  <a
                    style={{ color: blue, textDecoration: 'none' }}
                    href={`mailto:${process.env.REACT_APP_DROOKY_EMAIL}`}
                  >
                    {t('usefulLinks.contact')}
                  </a>
                </Typography>
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Typography
                variant={'h3'}
                sx={{
                  lineHeight: { xs: '17.25px', md: '60px' },
                  mb: { xs: '20px', md: '30px' },
                }}
              >
                {t('boredLinks.title')}
              </Typography>
              <Button
                style={{ textTransform: 'none' }}
                onClick={() => redirect('privacyPolicy')}
              >
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  {t('boredLinks.terms')}
                </Typography>
              </Button>
              <br />
              <Button
                style={{ textTransform: 'none' }}
                onClick={() => redirect('legalNotice')}
              >
                <Typography
                  color="primary"
                  sx={{
                    lineHeight: { xs: '10px', md: '30px' },
                    fontSize: { xs: '10px', md: '20px' },
                    textAlign: 'left',
                  }}
                >
                  {t('boredLinks.legalNotice')}
                </Typography>
              </Button>
              <br />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </section>
  );
}
