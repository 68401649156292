import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Avatar, TextField } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    [theme.breakpoints.down('lg')]: {
      width: theme.spacing(11),
      height: theme.spacing(11),
    },
  },
  text_field_label: {
    color: theme.palette.primary.main,
  },
  text_field: {
    color: theme.palette.primary.main,
    '&:before': {
      borderColor: theme.palette.primary.main,
    },
    '&:after': {
      borderColor: theme.palette.primary.main,
    },
  },
  text_field_label_error: {
    color: theme.palette.error.main + ' !important',
  },
  text_field_error: {
    color: theme.palette.error.main + ' !important',
    '&:before': {
      borderColor: theme.palette.error.main + ' !important',
    },
    '&:after': {
      borderColor: theme.palette.error.main + ' !important',
    },
  },
}));

export default function InputWithIcon(props) {
  const { label, src, field, animal, handleChange, errors } = props;
  const classes = useStyles();
  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item>
        <Avatar
          variant="square"
          alt="img"
          className={classes.large}
          classes={{
            img: classes.img,
          }}
          src={src}
        />
      </Grid>
      <Grid item>
        <TextField
          id="animaName"
          label={label}
          {...field}
          InputLabelProps={{
            className: errors.animalName
              ? classes.text_field_label_error
              : classes.text_field_label,
          }}
          InputProps={{
            className: errors.animalName
              ? classes.text_field_error
              : classes.text_field,
          }}
          onChange={(e) => handleChange(e)}
          value={animal.name}
        />
      </Grid>
    </Grid>
  );
}
