import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '../../theme';

const useStyles = makeStyles((theme) => ({
  CustomButton: {
    strokeColor: theme.palette.secondary.main,
    width: (props) => props.width,
    height: (props) => props.height,
    fontSize: (props) => `${props.fontSize}rem`,
  },
  text: {
    position: 'relative',
    padding: '5px 15px',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: (props) => `${props.text}rem`,
    [theme.breakpoints.down('sm')]: {
      padding: '2px 8px',
    },
  },
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    '&:disabled': {
      backgroundColor: theme.palette.error.light,
    },
  },
}));

const ButtonCustom = ({ variant, ...props }) => {
  const { children, disabled } = props;

  const width = props.width || 'auto';
  const maxWidth = props.maxWidth || 'auto';
  const height = props.height || 'auto';
  const fontSize = props.fontSize || 1.0;
  const backgroundColor = variant
    ? theme.palette.secondary.main
    : theme.palette.primary.main;
  const color = variant
    ? theme.palette.primary.main
    : theme.palette.primary.contrastText;

  const classes = useStyles({
    width,
    height,
    maxWidth,
    fontSize,
  });
  return (
    <Button
      {...props}
      style={{
        width: width,
        height: height,
        maxWidth: maxWidth,
      }}
    >
      <div>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 108.40036 24.198435"
          preserveAspectRatio="none"
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            overflow: 'hidden',
            userSelect: 'none',
            touchAction: 'none',
          }}
        >
          <path
            style={{
              fill: !disabled
                ? backgroundColor
                : theme.palette.action.disabled,
            }}
            transform="translate(-36.261386,-77.963085)"
            d="m 37.414616,77.963085 106.105674,1.441803 0.86501,21.912792 -108.123914,0.57679 z"
          />
        </svg>
        <div
          className={classes.text}
          style={{
            color: !disabled ? color : theme.palette.text.disabled,
          }}
        >
          {children}
        </div>
      </div>
    </Button>
  );
};

export default ButtonCustom;
