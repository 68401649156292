import {useEffect, useState} from "react";
import _ from 'lodash';

export default function ReferenceIntersection(ref) {
    const [isIntersecting, setIntersecting] = useState(true)
    useEffect(() => {
        if (_.isNil(ref?.current)) return;
        const observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting)
        );
        observer.observe(ref.current);
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() };
    }, [ref]);
    return isIntersecting;
}
