import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import intro from '../../static/intro_2.svg';

const useStyles = makeStyles((theme) => ({
    img: {
        width: '50%',
        height: '100%',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

export default function Intro(props) {
    const classes = useStyles();

    return (
        <div style={{ height: '100vh', width: '100%', background:'white' }} {...props}>
            <img className={classes.img} alt="" src={intro} />
        </div>
    );
}