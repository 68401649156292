import { createTheme } from '@mui/material/styles';
import {themeCore, blue} from "./index";

let themeCusto = {...themeCore}
themeCusto.typography.h1.color = blue
themeCusto.typography.h2.color = blue
themeCusto.typography.h3.color = blue
themeCusto.typography.h4.color = blue
themeCusto.typography.h5.color = blue
themeCusto.typography.h6.color = blue

const theme = createTheme({
    ...themeCusto
});

export default theme;